import React from "react";
import { Grid, Typography, Box, Button, Stack } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// @ts-ignore
import Navigation from "./Navigation.tsx";
// @ts-ignore
import Footer from "./Footer.tsx";
// @ts-ignore
import Banner from "./Banner.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function About() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid style={{ minHeight: "100vh" }} container margin={0}>
      <Grid item xs={12} margin={2}>
        <Navigation mode="about" />
      </Grid>
      <Grid item xs={12} marginLeft={2} marginRight={2}>
        <Banner/>
        {/* <img src="./banner01.jpg" alt="Real Estate" style={{ width: "100%" }} /> */}
      </Grid>
      <Grid item xs={12} md={12} margin={2}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="服務範圍">
              <Tab
                label="公司簡介"
                {...a11yProps(0)}
                sx={{
                  fontSize: "24px",
                  color: "#1976d2", // Set default font color
                }}
              />
              {/* <Tab
                label="資質證書"
                {...a11yProps(1)}
                sx={{
                  fontSize: "24px",
                  color: "#1976d2", // Set default font color
                }}
              /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 1.5 }} // Increase line spacing
            >
              德記置業有限公司成立已有五十多年，熟識澳門的房地產市場情況，擁有專業物業評估隊伍，自1985年起為本澳各大金融機構、工商業客戶等提供專業澳門區物業估價服務，憑藉豐富的物業評估經驗以及強大資料庫支援，獲澳門房地產評估業協會認可，加入成為其會員之一，會員編號：A20007。
我司誠意為客戶提供本澳各類型房地產物業專業評估，及代辦理中國內地房地產估價服務，包括住宅、鋪位、寫字樓、別墅、停車位、地皮、全幢建築物或酒店等物業估值。
              {/* 德記置業有限公司成立已有五十多年，擁有專業物業評估隊伍，自1985年起為本澳各大金融機構、工商業客戶等提供專業澳門區物業估價服務，憑藉豐富的物業評估經驗以及強大資料庫支援，獲澳門房地產評估業協會認可，加入成為其會員之一，會員編號：A20007。 */}
            </Typography>
          </CustomTabPanel>

          {/* <CustomTabPanel value={value} index={1}>
            <Grid
              container
              justifyContent="center" // Center the stacks horizontally
              alignItems="center" // Center the stacks vertically
              spacing={2}
              rowSpacing={5}
            >
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    // href="澳門房地產評估業協會-會員證書.jpg"
                    // download="澳門房地產評估業協會-會員證書.jpg"
                    sx={{ backgroundColor: "white" }}
                  >
                    <img
                      src="澳門房地產評估業協會-會員證書.jpg"
                      alt="澳門房地產評估業協會-會員證書"
                      draggable="false"
                      width="300px"
                      style={{ display: "block", margin: "auto" }} // Center image within button
                    />
                  </Button>
                  <Button
                    variant="contained"
                    // href="澳門房地產評估業協會-會員證書.jpg"
                    // download="澳門房地產評估業協會-會員證書.jpg"
                    sx={{ fontSize: "20px", width: "350px" }}
                  >
                    澳門房地產評估業協會-會員證書
                  </Button>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    // href="澳門地產業總商會-商號會員證.jpg"
                    // download="澳門地產業總商會-商號會員證.jpg"
                    sx={{ backgroundColor: "white" }}
                  >
                    <img
                      src="澳門地產業總商會-商號會員證.jpg"
                      alt="澳門地產業總商會-商號會員證"
                      draggable="false"
                      width="400px"
                      style={{ display: "block", margin: "auto" }} // Center image within button
                    />
                  </Button>
                  <Button
                    variant="contained"
                    // href="澳門地產業總商會-商號會員證.jpg"
                    // download="澳門地產業總商會-商號會員證.jpg"
                    sx={{ fontSize: "20px", width: "350px" }}
                  >
                    澳門地產業總商會-商號會員證
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel> */}
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default About;
