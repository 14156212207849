/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

function Footer() {
  return (
    <Grid container>
      <Grid
        item
        xs={0}
        md={3}
        lg={4}
        style={{ backgroundColor: "#1976d2", padding: "0px 0px 0px 0px" }}
      ></Grid>

      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        style={{ backgroundColor: "#1976d2", padding: "20px 20px 20px 20px" }}
      >
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "20px" }}
        >
          德記置業有限公司
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          地址：澳門新口岸宋玉生廣場418號大豐銀行總行大廈1樓
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          電話：+853 8797 0604 (曾生) / 8797 0612 (林生)
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          傳眞：+853 8797 0619
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          郵箱：est@taifungbank.com
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          網址：https://www.takkei.com.mo
        </Typography>
        <Typography
          style={{ textAlign: "left", color: "white", fontSize: "14px" }}
        >
          Copyright 2024@德記置業有限公司 版權所有
        </Typography>
      </Grid>

      <Grid
        item
        xs={0}
        md={3}
        lg={4}
        style={{ backgroundColor: "#1976d2", padding: "0px 0px 0px 0px" }}
      ></Grid>
    </Grid>
  );
}

export default Footer;
