import moment from "moment";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

var baseURL: string;
var isLocalhost: boolean;
// alert(window.location.hostname);

if (window.location.hostname === "localhost") {
  isLocalhost = true;
  if (window.location.port === "8000") {
    baseURL = "https://" + window.location.hostname + ":8000";
  } else {
    baseURL = "https://" + window.location.hostname + ":443";
  }
} else {
  isLocalhost = false;
  baseURL = "https://" + window.location.hostname + ":443";
}

// if (window.location.hostname === "localhost") {
//   isLocalhost = true;
//   baseURL = "https://" + window.location.hostname + ":8000";
// } else {
//   isLocalhost = false;
//   baseURL = "https://" + window.location.hostname + ":443";
// }
const MAX_PIC_COUNT = 12; // count
const MAX_UPLOAD_SIZE = 5000000; // MB
const DEVELOPMENT = window.location.hostname === "localhost" ? true : false; // true;

const currencyValueFormatter = (value: any) => {
  if (value === null || value === "" ) {
    return "";
  } else if (isNaN(value)){
    return String(value).replace(/[^\d.-]/g, "");
    // return String(value);
  } else {
    const formattedValue = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // const formattedValue = value;
    return formattedValue;
  }
};

function construct_building_code(selectedRow: any) {
  return (
    selectedRow.code_branch +
    "-" +
    selectedRow.code_type +
    "-" +
    selectedRow.code_year +
    "-" +
    selectedRow.code_month +
    "-" +
    selectedRow.code_serial
  );
}

function removeSQLInjectionKeywords(input: any) {
  const sensitiveKeywords = [
    "SELECT",
    "UPDATE",
    "DELETE",
    "INSERT",
    "DROP",
    "CREATE",
    "ALTER",
    "TRUNCATE",
    "EXECUTE",
    "UNION",
    // Add more keywords as needed
  ];
  const keywordRegex = new RegExp(
    `\\b(${sensitiveKeywords.join("|")})\\b`,
    "gi"
  );
  const sanitizedInput = input.replace(keywordRegex, "");
  return sanitizedInput;
}

function timeforfilename(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    // return moment(d).format("YYYY-MM-DD HH:mm:ss")
    return moment(d).format("YYYY-MM-DD HH_mm_ss");
  }
}

function timeformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    // return moment(d).format("YYYY-MM-DD HH:mm:ss")
    return moment(d).format("YYYY-MM-DD HH:mm");
  }
}

function dateformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("YYYY-MM-DD");
  }
}
function yearformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("YYYY");
  }
}
function monthformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("MM");
  }
}
function dayformat(d: Date | string) {
  if (d === null || d === "") {
    // if (d === null){
    return "";
  } else {
    return moment(d).format("DD");
  }
}
function hourformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("HH");
  }
}
function minuteformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("mm");
  }
}
function secondformat(d: Date | string) {
  // if (d === null){
  if (d === null || d === "") {
    return "";
  } else {
    return moment(d).format("ss");
  }
}

function isValidDate(d: Date | string) {
  return moment(d, "YYYY-MM-DD", true).isValid();
}

function isValidTime(d: Date | string) {
  // return moment(d, "YYYY-MM-DD HH:mm:ss", true).isValid();
  return moment(d, "YYYY-MM-DD HH:mm", true).isValid();
}

function isNumber(value: string): boolean {
  const newvalue = value.replace(/,/g, "");
  // return newvalue != null && newvalue !== "" && !isNaN(Number(newvalue));
  return newvalue != null || newvalue !== "" || !isNaN(Number(newvalue));
  // return value != null && value !== "" && !isNaN(Number(value));
}

function alert_error(error: any) {
  console.error(error);
  if (error.response && error.response.data && error.response.data.detail) {
    alert('錯誤代號：'+error.response.status + "\n時間位置：" + error.response.data.detail);
  } else {
    // alert(error.response.status + " - " + error);
    alert("錯誤：（內容不詳）" + error);
  }
}

function validTelNumber(telno: any) : boolean {
  const phoneNumberPattern = /^[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*\d[\d()+\s-]*$/;    
  const isValid = phoneNumberPattern.test(telno);
  return isValid; 
}

function saveCurrentTimeToLocalStorage(): void {
  const currentTime: string = timeformat(new Date());
  localStorage.setItem("token_time", currentTime);
}


export {
  baseURL,
  timeformat,
  dateformat,
  yearformat,
  monthformat,
  dayformat,
  hourformat,
  minuteformat,
  secondformat,
  isValidDate,
  isValidTime,
  isNumber,
  MAX_PIC_COUNT,
  MAX_UPLOAD_SIZE,
  removeSQLInjectionKeywords,
  alert_error,
  construct_building_code,
  // wait,
  DEVELOPMENT,
  currencyValueFormatter,
  isLocalhost,
  // formatNumber,
  validTelNumber,
  saveCurrentTimeToLocalStorage,
  timeforfilename,
};
