/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import {
  Button,
  FormControl,
  TextField,
  Box,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import {
  baseURL,
  alert_error,
  saveCurrentTimeToLocalStorage,
} from "./settings";
import axios from "axios";
// @ts-ignore
import OutlinedDiv from "./OutlinedDiv.tsx";

function Add({ add, setAdd, defaultRow, appendRows, setSelectedIds }: any) {
  const [formValues, setFormValues] = useState(defaultRow);

  const handleClose = (reason: any) => {
    // if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return;
    setAdd(false);
  };

  useEffect(() => {
    console.log("Add");
    // set the default to A 諮詢
    setFormValues({ ...defaultRow, code_branch: "A" }); 
  }, [defaultRow]);

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;
    if (name === "code_branch_type") {
      // A or 00
      name = "code_branch";
      let code_type = formValues.code_type; // if already selected
      if (code_type === "H$" || code_type === "T$" || code_type === "O$") {
        setFormValues({
          ...formValues,
          code_branch: defaultRow.code_branch, // forced to 00
        });
        return; // no more action
      }
    } else if (name === "code_type") {
      // M$, ...
      console.log("value= " + value);
      if (value === "H$" || value === "T$" || value === "O$") {
        setFormValues({
          ...formValues,
          code_branch: defaultRow.code_branch,
          code_type: value,
        });
        return;
      }
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function handleAdd(event: any) {
    event.preventDefault();
    var form_data = new FormData();
    for (var key in formValues) {
      if (formValues[key] != null) {
        form_data.append(key, formValues[key]);
      }
    }

    if (form_data.get("code_type") === "") {
      alert("請填寫類別。");
      return;
    }

    axios({
      method: "POST",
      url: baseURL + "/building_code",
      data: form_data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        setFormValues(response.data.list);
        appendRows(response.data.list);
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();
      })
      .catch((error) => {
        alert_error(error);
        // console.error(error);
        // alert(error.response.status + ' - ' + error.response.data.detail);
        // alert('取號錯誤！');
        return;
      });
  }

  return (
    <Dialog
      // fullWidth
      maxWidth="xs"
      open={add}
    >
      {/* onClose={handleClose}> */}
      <FormControl margin="normal">
        <Box component="form" onSubmit={handleAdd} sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ marginLeft: 3 }}>
            新增申請
          </Typography>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <OutlinedDiv label="選項" fullWidth={true}>
                  <RadioGroup
                    row
                    name="code_branch_type"
                    value={formValues.code_branch}
                    // value="A"
                    onChange={handleInputChange}
                    // sx={{width:300, height:80}}
                  >
                    <FormControlLabel
                      value="A"
                      label="諮詢"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={defaultRow.code_branch}
                      label="報告"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </OutlinedDiv>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  focused
                  fullWidth
                  id="code_type"
                  name="code_type"
                  value={formValues.code_type || ""}
                  onChange={handleInputChange}
                  InputProps={{ sx: { height: 40 } }}
                  label="類別" // 類別
                  select
                  // required
                >
                  <MenuItem value={"C$"}>C$ 辦公室\寫字樓</MenuItem>
                  <MenuItem value={"F$"}>F$ 工業\廠商\工場\倉庫</MenuItem>
                  <MenuItem value={"L$"}>L$ 商業\商舖\商場</MenuItem>
                  <MenuItem value={"M$"}>M$ 住宅\居住</MenuItem>
                  <MenuItem value={"P$"}>
                    P$ 汽車停車位\電單車停車位\停車場\公眾停車場
                  </MenuItem>
                  <MenuItem value={"T$"}>T$ 地皮(包括總造價及總產值)</MenuItem>
                  <MenuItem value={"H$"}>H$ 酒店\公寓</MenuItem>
                  <MenuItem value={"O$"}>O$ 全幢物業及其他(以上皆非)</MenuItem>
                  <MenuItem value={"N$"}>N$ 内地房地產(代辦理)</MenuItem>
                </TextField>
              </Grid>
              {/* <Grid item xs={6} md={3}>
                <TextField focused fullWidth color="secondary" 
                  InputProps={{sx:{height:40}, readOnly:true}}
                  onChange={handleInputChange} label="年份" id="code_year" name="code_year" 
                  value={formValues.code_year||""}/>
              </Grid> */}
              {/* <Grid item xs={6} md={3}>
                <TextField focused fullWidth color="secondary" 
                  InputProps={{sx:{height:40}, readOnly:true}}
                  onChange={handleInputChange} label="月份" id="code_month" name="code_month" 
                  value={formValues.code_month||""}/>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <TextField focused fullWidth color="secondary" 
                  InputProps={{sx:{height:40}, readOnly:true}}
                  onChange={handleInputChange} label="序號" id="code_serial" name="code_serial" 
                  value={formValues.code_serial||""}/>
              </Grid> */}
              <Grid item xs={3} md={3}></Grid>
              <Grid item xs={3} md={3}>
                <Button fullWidth variant="contained" type="submit">
                  取號
                </Button>
              </Grid>
              <Grid item xs={3} md={3}>
                <Button fullWidth variant="contained" onClick={handleClose}>
                  離開
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </FormControl>
    </Dialog>
  );
}

export default Add;
