/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import {
  Button,
  FormControl,
  TextField,
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Snackbar,
  Alert,
  useMediaQuery,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import domtoimage from "dom-to-image";
// import html2canvas from "html2canvas";

import { useNavigate } from "react-router-dom";
import { FileWithPath } from "file-selector";
import { Dialog } from "@mui/material";
import axios from "axios";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// @ts-ignore
import {
  baseURL,
  isValidDate,
  isNumber,
  timeformat,
  dateformat,
  MAX_PIC_COUNT,
  MAX_UPLOAD_SIZE,
  alert_error,
  construct_building_code,
  currencyValueFormatter,
  yearformat,
  validTelNumber,
  saveCurrentTimeToLocalStorage,
  timeforfilename,
  // @ts-ignore
} from "./settings.tsx";
// @ts-ignore
import BasicUploadFiles from "./BasicUploadFiles.tsx";
// @ts-ignore
import OutlinedDiv from "./OutlinedDiv.tsx";
// @ts-ignore
// @ts-ignore
import Floor from "./Floor.tsx";
import "./App.css";

function Edit({
  edit,
  setEdit,
  selectedRow,
  setSelectedRow,
  updateRows,
  handleMove,
  // removeSubmit,
  downloadPDF,
  copyRow,
  searchOption,
  setPicture,
}: // username,
any) {
  const [isDivVisible, setIsDivVisible] = useState(true);
  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const userAgent = navigator.userAgent.toLowerCase();
  // Check for common keywords in user agent string
  const isDesktop = !/mobile|tablet/.test(userAgent);

  const navigate = useNavigate();
  const [upload_progress, setUploadProgress] = useState(0);
  const [clearAcceptedFiles, setClearAcceptedFiles] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isSaved, setIsSaved] = useState(true);
  const [username, setUserName] = useState("");
  const [code_branch, setCodeBranch] = useState("");
  const [application_code_label, set_application_code_label] = useState("");
  const [evaluationLabels, setEvaluationLabels] = useState([
    "時點估值",
    "時點租值",
    "重置價值",
    "迫銷價值",
  ]);
  const [displayLabel, setDisplayLabel] = useState(false);

  const [floor, setFloor] = useState(false);
  const [isReply, setIsReply] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const captureElement = (element: HTMLElement) => {
    domtoimage
      .toPng(element)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download =
          "TK2023 " +
          construct_building_code(selectedRow) +
          " " +
          timeforfilename(new Date()) +
          ".png";
        // link.download = "TK2023-Screen-Shot.png"; // Change the file name and extension as desired
        link.click();
      })
      .catch((error) => {
        console.error("Failed to capture screen: ", error);
      });
  };

  const confirmMove = (number: any) => {
    if (isSaved === false) {
      if (window.confirm("馬上儲存資料？") === true) {
        if (handleSave() === false) {
          return;
        }
      }
    }
    setIsSaved(true); // give up saving
    handleMove(number);
  };
  const set_upload_progress = (number: any) => {
    setUploadProgress(number);
  };

  const handleCaptureClick = () => {
    setIsDivVisible(true);
  };

  const handleDownloadPDF = () => {
    const code_reply = construct_building_code(selectedRow);
    downloadPDF(code_reply);
  };

  const handleCopyRow = () => {
    const code_reply = construct_building_code(selectedRow);
    copyRow(code_reply);
  };

  useEffect(() => {
    const username_temp = localStorage.getItem("username") || "";
    const code_branch_temp = localStorage.getItem("code_branch") || "";
    setUserName(username_temp);
    setCodeBranch(code_branch_temp);
    setUploadProgress(0);
  }, []); //  2023.12.13

  useEffect(() => {
    if (isDivVisible) {
      const targetElement = containerRef.current
        ?.firstElementChild as HTMLElement;
      if (targetElement) {
        captureElement(targetElement);
      }
    }
    setIsDivVisible(false);
  }, [isDivVisible]);

  useEffect(() => {
    if (selectedRow.code_type.substring(0, 1) === "T") {
      setEvaluationLabels(["地皮價值", "總產值", "總造價", "---"]);
      setDisplayLabel(false);
    } else if (selectedRow.code_type.substring(0, 1) === "H") {
      setEvaluationLabels(["地皮價值", "總產值", "總造價", "估值(買賣價)"]);
      setDisplayLabel(true);
    } else {
      setEvaluationLabels(["時點估值", "時點租值", "重置價值", "迫銷價值"]);
      setDisplayLabel(true);
    }
    // judge the table is submission (M$) or reply (M1)
    const is_reply =
      selectedRow.code_type.substring(1, 2) !== "$" ||
      String(selectedRow.status) === "4";
    setIsReply(is_reply);

    if (searchOption === "0") {
      set_application_code_label("申請編號");
    } else if (searchOption === "4") {
      set_application_code_label("回覆編號");
    } else {
      set_application_code_label("未知編號");
    }

    setSelectedRow({
      ...selectedRow,
      pic_count: selectedRow.picture
        ? selectedRow.picture.split(/\r\n|\r|\n/).length
        : 0,
    });
  }, [selectedRow.code_type, selectedRow.picture, searchOption]);

  useEffect(() => {
    setIsEditable(
      (selectedRow.application_user === username || code_branch === "0000") &&
        (selectedRow.job_assign === null || selectedRow.job_assign === "") &&
        !isReply
    );
  }, [
    selectedRow.application_user,
    username,
    code_branch,
    selectedRow.job_assign,
    isReply,
  ]);

  useEffect(() => {
    setClearAcceptedFiles(true);
  }, [selectedRow.row_id]);

  function error_action(error: any) {
    alert_error(error);
    if (error.response.status === 401) {
      navigate("/main");
    }
  }

  // const handleClose = (event: any) => {
  //   if (isSaved === false) {
  //     if (window.confirm("放棄儲存資料？") === false) {
  //       return;
  //     }
  //   }
  //   updateRows(selectedRow);
  //   setEdit(false);
  // };

  const handleClose = (event: any) => {
    if (isSaved === false) {
      if (window.confirm("馬上儲存資料？") === true) {
        if (handleSave() === false) {
          return;
        }
      }
    }
    // updateRows(selectedRow);
    setIsSaved(true);
    setEdit(false);
  };

  // for DatePicker
  function handleDateChange(name: string, new_value: any) {
    if (!isEditable) {
      return;
    }

    setIsSaved(false); // is modified without saving

    setSelectedRow({
      ...selectedRow,
      [name]: dateformat(new_value),
    });
  }

  const handleInputChange = (e: any) => {
    if (!isEditable) {
      return;
    }

    setIsSaved(false); // is modified without saving
    // alert(isSaved);

    let { name, value } = e.target;

    if (
      typeof e.target.tagName != "undefined" &&
      e.target.tagName.toLowerCase() === "input" &&
      e.target.getAttribute("type") === "checkbox"
    ) {
      value = e.target.checked ? 1 : 0;
      if (selectedRow.code_type === "T$" || selectedRow.code_type === "H$") {
        setSelectedRow({
          ...selectedRow,
          [name]: value,
        });
        return;
      } else if (
        value === 1 &&
        (name === "evaluation_item01" ||
          name === "evaluation_item03" ||
          name === "evaluation_item04")
      ) {
        setSelectedRow({
          ...selectedRow,
          evaluation_item02: 0,
          [name]: value,
        });
        return;
      } else if (name === "evaluation_item02" && value === 1) {
        setSelectedRow({
          ...selectedRow,
          evaluation_item01: 0,
          evaluation_item03: 0,
          evaluation_item04: 0,
          [name]: value,
        });
        return;
      }
    }

    if (name === "registration_area") {
      if (!isNumber(value)) {
        setAlertMessage("登記面積：（" + value + "）不是有效數值。");
        setOpenSnackbarWarning(true);
        return;
      } else {
        const newvalue = value.replace(/,/g, "");
        const max_area = 100000;
        if (Number(newvalue) > max_area) {
          setAlertMessage(
            "登記面積：（" + newvalue + "）數值超過" + String(max_area) + "。"
          );
          setOpenSnackbarWarning(true);
          return;
        }
        setSelectedRow({
          ...selectedRow,
          [name]: newvalue,
        });
        return;
      }
    }

    setSelectedRow({
      ...selectedRow,
      [name]: value,
    });
  };

  // function handleSave(event: any, submit = "0") {
  function handleSave(submit = "0") {
    // event.preventDefault();

    if (
      selectedRow.job_assign !== null &&
      selectedRow.job_assign.trim() !== ""
    ) {
      alert("已經正式提交，不能再次修改或提交。");
      return true;
    }

    var form_data = new FormData();
    for (var key in selectedRow) {
      if (selectedRow[key] != null) {
        form_data.append(key, selectedRow[key]);
      } else {
        if (
          [
            "evaluation_item01",
            "evaluation_item02",
            "evaluation_item03",
            "evaluation_item04",
            "re_evaluation",
          ].includes(key)
        ) {
          form_data.append(key, "0");
        }
      }
    }

    let validation_string = "";

    let consignerTelString = String(form_data.get("consigner_tel") || "");
    if (consignerTelString !== "" && !validTelNumber(consignerTelString)) {
      // return;
      validation_string =
        validation_string +
        "•電話號碼容許數字、括號()、連接符-，要求8位數字或以上。\n";
    }

    let dateString = String(form_data.get("evaluation_date"));
    if (!isValidDate(dateString)) {
      // alert("評估時點：" + dateString + "不是有效日期（YYYY-MM-DD）。"); // default now
      // return;
      validation_string =
        validation_string +
        "•評估時點：" +
        dateString +
        "不是有效日期（YYYY-MM-DD）。\n";
    } else if (dateString < "2010-01-01") {
      validation_string =
        validation_string + "•評估時點：" + dateString + "早於2010-01-01。\n";
    }

    dateString = String(form_data.get("consign_date"));
    if (
      dateString !== null &&
      dateString !== "null" &&
      dateString !== "" &&
      !isValidDate(dateString)
    ) {
      validation_string =
        validation_string +
        "•委託日期：" +
        dateString +
        "不是有效日期（YYYY-MM-DD）。\n";
    }

    let year_string = form_data.get("license_issue_year");
    let current_year = yearformat(new Date());
    if (year_string === null || String(year_string).trim() === "") {
      // pass
    } else if (!isNumber(String(year_string))) {
      validation_string =
        validation_string +
        "•准照發出年份：" +
        year_string +
        "不是有效年份。\n";
    } else if (
      Number(year_string) < 1000 ||
      Number(year_string) > Number(current_year)
    ) {
      validation_string =
        validation_string +
        "•准照發出年份：" +
        year_string +
        "超出1000和" +
        current_year +
        "的有效年份範圍。\n";
    }

    if (submit === "1") {
      let field_string = "";
      if (
        form_data.get("evaluation_item01") !== "1" &&
        form_data.get("evaluation_item02") !== "1" &&
        form_data.get("evaluation_item03") !== "1" &&
        form_data.get("evaluation_item04") !== "1"
      ) {
        field_string =
          field_string === ""
            ? field_string + "「估價類別」"
            : field_string + "、「估價類別」";
      }

      if (
        form_data.get("consigner_tel") === null ||
        String(form_data.get("consigner_tel")).trim() === ""
      ) {
        field_string =
          field_string === ""
            ? field_string + "「聯絡電話」"
            : field_string + "、「聯絡電話」";
      }
      if (
        form_data.get("consigner_contact") === null ||
        String(form_data.get("consigner_contact")).trim() === ""
      ) {
        field_string =
          field_string === ""
            ? field_string + "「聯絡人」"
            : field_string + "、「聯絡人」";
      }
      if (
        form_data.get("building_name") === null ||
        String(form_data.get("building_name")).trim() === ""
      ) {
        field_string =
          field_string === ""
            ? field_string + "「物業名稱」"
            : field_string + "、「物業名稱」";
      }
      if (
        form_data.get("picture") === null ||
        String(form_data.get("picture")).trim() === ""
      ) {
        field_string =
          field_string === ""
            ? field_string + "「附件」"
            : field_string + "、「附件」";
      }
      if (field_string !== "") {
        validation_string =
          validation_string + "•以下欄目不能留空：" + field_string + "\n";
      }
    }

    if (validation_string !== "") {
      validation_string = "欄目校驗錯誤，請修正：\n" + validation_string;
      alert(validation_string);
      return false;
    }

    if (
      submit === "1" &&
      window.confirm("現在正式提交？將被移至待覆，之後不能修改。") !== true
    ) {
      return false;
    }

    axios({
      method: "POST",
      url: baseURL + "/sms/save?submit=" + submit,
      data: form_data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        setIsSaved(true); // is modified without saving

        // fastapi 回覆原來的record，已經有conversion_date
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();
        if (response.data.list.conversion_date != null) {
          // if (response.data.conversion_date != null) {
          alert(
            timeformat(response.data.conversion_date) +
              " 已經轉換，不能再次提交。"
          );
          return true;
        }
        if (submit === "0") {
          updateRows(selectedRow);
          setAlertMessage("估價資料：儲存成功。");
          setOpenSnackbarSuccess(true);
        } else {
          // submit === '1'
          setSelectedRow({
            ...selectedRow,
            job_assign: response.data.list.job_assign,
          });
          // alert("提交成功。");
          setAlertMessage("提交成功，感謝支持！\n我們會盡快聯絡您。");
          setOpenSnackbarSuccess(true);
        }
      })
      .catch((error) => {
        error_action(error);
      });
  }

  function clearPictures() {
    setSelectedRow({
      ...selectedRow,
      picture: "",
      pic_count: 0,
      appendix01: 0,
      modification_count: 0,
    });
    setIsSaved(false);
    setUploadProgress(0);
  }

  function progress(progressEvent: any) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  }

  function handleUpload(acceptedFiles: FileWithPath[]) {
    setUploadProgress(0);

    var form_data = new FormData();
    acceptedFiles.forEach((file) => {
      form_data.append("files", file);
    });
    const building_code = construct_building_code(selectedRow);

    // 計算上傳附件後的總數
    var longstr = selectedRow.picture || "";
    const modification_count = selectedRow.modification_count || "0.000";

    let filesize = Number(modification_count);

    acceptedFiles.forEach((file) => {
      let picture_list = longstr.split(/\r\n|\r|\n/);
      if (longstr.trim() === "") {
        longstr = building_code + "~" + file.path;
        filesize = filesize + file.size;
      } else if (!picture_list.includes(building_code + "~" + file.path)) {
        longstr = longstr + "\r\n" + building_code + "~" + file.path;
        filesize = filesize + file.size;
      }
    });

    // 判斷超過 MAX_PIC_COUNT (6或12)個
    var list_count = longstr.split(/\r\n|\r|\n/).length;
    if (list_count > MAX_PIC_COUNT) {
      alert(
        "附件總數將會大於" +
          MAX_PIC_COUNT.toString() +
          "個，不能上傳。考慮清空附件，然後重新上傳。"
      );
      return;
    }

    if (filesize > MAX_UPLOAD_SIZE) {
      alert(
        "上傳附件大小將會大於" +
          (MAX_UPLOAD_SIZE / 1000000).toString() +
          "MB，不能上傳。考慮清空附件，然後重新上傳。"
      );
      return;
    }

    form_data.append("building_code", building_code);

    axios({
      method: "POST",
      // url: baseURL + '/uploadfiles/'+building_code,
      url: baseURL + "/uploadfiles",
      data: form_data,
      onUploadProgress: progress,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        var longstr = selectedRow.picture || "";
        acceptedFiles.forEach((file) => {
          let picture_list = longstr.split(/\r\n|\r|\n/);
          if (longstr.trim() === "") {
            longstr = building_code + "~" + file.path;
          } else if (!picture_list.includes(building_code + "~" + file.path)) {
            longstr = longstr + "\r\n" + building_code + "~" + file.path;
          }
        });
        setSelectedRow({
          ...selectedRow,
          picture: longstr,
          pic_count: longstr.split(/\r\n|\r|\n/).length,
          appendix01: longstr.split(/\r\n|\r|\n/).length > 0 ? 1 : 0,
          modification_count: filesize,
        });
        setIsSaved(false);
        alert("上傳結果訊息：" + response.data.message);
      })
      .catch((error) => {
        alert("附件上傳失敗，檢查後再嘗試。");
        error_action(error);
        return;
      });
  }

  function handleCloseSnackbar() {
    setOpenSnackbarSuccess(false);
    setOpenSnackbarWarning(false);
  }

  // const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      // open={edit} onClose={handleClose}>
      open={edit}
      sx={{
        "& .MuiPaper-root": {
          // backgroundColor: "#FFFDF6", // Replace 'red' with your desired background color
          backgroundColor: "white", // Replace 'red' with your desired background color
        },
      }}
    >
      <FormControl margin="normal">
        <Box
          component="form"
          sx={{
            flexGrow: 2,
            margin: "0px",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <Grid container columnSpacing={1} rowSpacing={1} alignItems="center">
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                sx={{
                  bgcolor: "#5d4037",
                  color: "#ffffff",
                  padding: "8px 0px",
                  border: "3px solid #5d4037",
                  "&:hover": {
                    bgcolor: "#8d6e63",
                  },
                }}
                variant="contained"
                onClick={() => confirmMove(-1)}
              >
                上筆記錄
              </Button>
            </Grid>
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                color="secondary"
                sx={{
                  padding: "8px 0px",
                  border: "3px solid #9c27b0",
                }}
                variant="contained"
                onClick={() => confirmMove(1)}
              >
                下筆記錄
              </Button>
            </Grid>
            <Grid item xs={12 / 5}>
              {isEditable && (
                <Button
                  fullWidth
                  color="success"
                  sx={{
                    padding: "8px 0px",
                    border:
                      isSaved === false
                        ? "3px solid #FF0000"
                        : "3px solid #008800",
                  }}
                  variant="contained"
                  // onClick={(event) => {
                  //   handleSave(event, "0");
                  // }}
                  onClick={() => {
                    handleSave("0");
                  }}
                >
                  儲存申請
                </Button>
              )}
            </Grid>
            <Grid item xs={12 / 5}>
              {isEditable && (
                <Button
                  fullWidth
                  color="warning"
                  sx={{ padding: "8px 0px", border: "3px solid #ed6c02" }}
                  variant="contained"
                  // onClick={(event) => {
                  //   handleSave(event, "1");
                  // }}
                  onClick={() => {
                    handleSave("1");
                  }}
                >
                  提交申請
                </Button>
              )}
            </Grid>
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                color="error"
                sx={{
                  padding: "8px 0px",
                  border: "3px solid #d32f2f",
                }}
                variant="contained"
                onClick={handleClose}
              >
                關閉記錄
              </Button>
            </Grid>
          </Grid>
        </Box>

        <div ref={containerRef} id="my-node">
          <Box
            component="form"
            sx={{
              flexGrow: 2,
              margin: "0px",
              padding: "20px",
              backgroundColor: "white",
            }}
          >
            <div id="heading" style={{ marginBottom: "20px" }}>
              <Typography
                id="theTop"
                variant="h4"
                sx={{
                  marginTop: 0,
                  marginBottom: 1,
                  paddingLeft: 0,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                德記置業有限公司
              </Typography>
              <Typography
                id="theTop"
                variant="h5"
                sx={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingLeft: 0,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                SOCIEDADE DE FOMENTO PREDIAL TAK KEI, LIMITADA.
              </Typography>
              <Typography
                id="theTop"
                variant="h6"
                sx={{
                  marginTop: 0,
                  marginBottom: 2,
                  paddingLeft: 0,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                TAI FUNG BANK BLDG.
              </Typography>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                alignItems="center"
              >
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    澳門新口岸
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    418 ALAMEDA DR. CARLOS
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    TEL:(853)8797 0604, 8797 0612
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    宋玉生廣場418號1樓
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    D'ASSUMPÇÃO, 1 ANDAR, MACAU
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body2"
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    FAX:(853)8797 0619
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* )} */}

            <Typography
              id="theTop"
              variant="h6"
              sx={{
                marginTop: 0,
                marginBottom: 2,
                paddingLeft: 0,
                color: "#663300",
                borderBottom: "3px dashed #663300",
                textAlign: "left",
              }}
            >
              審批資料
            </Typography>

            <Grid
              container
              columnSpacing={1}
              rowSpacing={1}
              alignItems="center"
            >
              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  {application_code_label}
                </Typography>
              </Grid>
              <Grid item xs={8} md={2.2}>
                <TextField
                  color="success"
                  focused
                  // fullWidth
                  InputProps={{ sx: { height: 40, width: 200 } }}
                  id="application_code"
                  name="application_code"
                  value={
                    selectedRow.code_branch +
                    "-" +
                    selectedRow.code_type +
                    "-" +
                    selectedRow.code_year +
                    "-" +
                    selectedRow.code_month +
                    "-" +
                    selectedRow.code_serial
                  }
                />
              </Grid>

              <Grid item xs={4} md={2}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  來源編號
                </Typography>
              </Grid>
              <Grid item xs={8} md={2.2}>
                <TextField
                  color="success"
                  InputProps={{ sx: { height: 40, width: 200 } }}
                  inputProps={{ readOnly: true }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="seed_code"
                  name="seed_code"
                  value={selectedRow.seed_code || ""}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4} md={1.5}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  申請類別*
                </Typography>
              </Grid>
              <Grid item xs={8} md={3}>
                <OutlinedDiv
                  color="success"
                  // fullWidth
                  height="40px"
                  width="200px"
                >
                  <RadioGroup
                    row
                    name="code_branch"
                    value={selectedRow.code_branch}
                  >
                    <FormControlLabel
                      value="A"
                      label="諮詢"
                      control={
                        <Radio checked={selectedRow.code_branch === "A"} />
                      }
                    />
                    <FormControlLabel
                      value={selectedRow.code_branch}
                      label="報告"
                      control={
                        <Radio checked={selectedRow.code_branch !== "A"} />
                      }
                    />
                  </RadioGroup>
                </OutlinedDiv>
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  申請時間
                </Typography>
              </Grid>
              <Grid item xs={8} md={2.2}>
                <TextField
                  color="success"
                  InputProps={{ sx: { height: 40, width: 200 } }}
                  inputProps={{ readOnly: true }}
                  focused
                  onChange={handleInputChange}
                  id="application_date"
                  name="application_date"
                  value={selectedRow.application_date || ""}
                />
              </Grid>

              <Grid item xs={4} md={2}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  申請人員
                </Typography>
              </Grid>
              <Grid item xs={8} md={2.2}>
                <TextField
                  color="success"
                  InputProps={{ sx: { height: 40, width: 200 } }}
                  inputProps={{ readOnly: true }}
                  focused
                  onChange={handleInputChange}
                  id="application_user"
                  name="application_user"
                  value={selectedRow.application_user || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.5}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }}
                >
                  經辦人員
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  color="success"
                  InputProps={{ sx: { height: 40, width: 200 } }}
                  inputProps={{ readOnly: true }}
                  focused
                  onChange={handleInputChange}
                  id="conversion_user"
                  name="conversion_user"
                  value={selectedRow.conversion_user || ""}
                />
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{
                marginTop: 3,
                marginBottom: 2,
                paddingLeft: 0,
                color: "#663300",
                // backgroundColor: "#CC9966",
                borderBottom: "3px dashed #663300",
                textAlign: "left",
              }}
              // sx={{ margin: 1, color: "#663300" }}
            >
              委託方資料
            </Typography>

            <Grid
              container
              columnSpacing={1}
              rowSpacing={1}
              alignItems="center"
              // justifyContent="center"
            >
              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }} // #1976d2
                >
                  委託公司
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  color="success"
                  inputProps={{ maxLength: 40, readOnly: true }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="consigner_company"
                  name="consigner_company"
                  value={selectedRow.consigner_company || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.2}>
                <Typography
                  variant="body1"
                  sx={{ color: "green", textAlign: "right" }} // #1976d2
                >
                  委託部門/分行
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  color="success"
                  inputProps={{ maxLength: 40, readOnly: true }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="consigner_branch"
                  name="consigner_branch"
                  value={selectedRow.consigner_branch || ""}
                />
              </Grid>

              <Grid item xs={4} md={0.8}>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ textAlign: "right" }}
                >
                  聯絡人＊
                </Typography>
              </Grid>
              <Grid item xs={8} md={1.5}>
                <TextField
                  color="error"
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="consigner_contact"
                  name="consigner_contact"
                  value={selectedRow.consigner_contact || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.0}>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ textAlign: "right" }}
                >
                  聯絡電話＊
                </Typography>
              </Grid>
              <Grid item xs={8} md={2.3}>
                <TextField
                  color="error"
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 40 } }}
                  fullWidth
                  focused
                  onChange={handleInputChange}
                  id="consigner_tel"
                  name="consigner_tel"
                  value={selectedRow.consigner_tel || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  聯絡傳真
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="consigner_fax"
                  name="consigner_fax"
                  value={selectedRow.consigner_fax || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.2}>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ textAlign: "right" }}
                >
                  評估時點＊
                </Typography>
              </Grid>
              <Grid item xs={8} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <FormControl>
                    <Box width={150}>
                      <DatePicker
                        value={selectedRow.evaluation_date || ""}
                        onChange={(newValue) => {
                          handleDateChange("evaluation_date", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} color="error" focused />
                        )}
                        inputFormat="yyyy/MM/DD"
                        InputProps={{ sx: { height: 40 } }}
                      />
                    </Box>
                  </FormControl>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4} md={1.3}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  客戶名稱
                </Typography>
              </Grid>
              <Grid item xs={8} md={4.8}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ sx: { height: 40 } }}
                  fullWidth
                  focused
                  onChange={handleInputChange}
                  id="client_name"
                  name="client_name"
                  value={selectedRow.client_name || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ textAlign: "right" }}
                >
                  估價類別＊
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={5.2}
                sx={{ display: "flex", alignItems: "top" }}
              >
                <OutlinedDiv
                  color="error"
                  // label="估價類別＊"
                  fullWidth
                  height={isSmallScreen ? 120 : 40}
                >
                  <FormControlLabel
                    sx={{ height: 6 }}
                    label={
                      <Typography sx={{ fontSize: 16 }}>
                        {evaluationLabels[0]}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={handleInputChange}
                        value={1}
                        checked={selectedRow.evaluation_item01 === 1}
                        id="evaluation_item01"
                        name="evaluation_item01"
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ height: 6 }}
                    label={
                      <Typography sx={{ fontSize: 16 }}>
                        {evaluationLabels[1]}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={handleInputChange}
                        value={1}
                        checked={selectedRow.evaluation_item02 === 1}
                        id="evaluation_item02"
                        name="evaluation_item02"
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ height: 6 }}
                    label={
                      <Typography sx={{ fontSize: 16 }}>
                        {evaluationLabels[2]}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={handleInputChange}
                        value={1}
                        checked={selectedRow.evaluation_item03 === 1}
                        id="evaluation_item03"
                        name="evaluation_item03"
                      />
                    }
                  />
                  {displayLabel && (
                    <FormControlLabel
                      sx={{ height: 6 }}
                      label={
                        <Typography sx={{ fontSize: 16 }}>
                          {evaluationLabels[3]}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onChange={handleInputChange}
                          value={1}
                          checked={selectedRow.evaluation_item04 === 1}
                          id="evaluation_item04"
                          name="evaluation_item04"
                        />
                      }
                    />
                  )}
                </OutlinedDiv>
              </Grid>

              <Grid item xs={4} md={0.8}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  評估目的
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 40 } }}
                  select
                  fullWidth
                  focused
                  onChange={handleInputChange}
                  id="evaluation_purpose"
                  name="evaluation_purpose"
                  value={selectedRow.evaluation_purpose || ""}
                >
                  <MenuItem value={"出售"}>出售</MenuItem>
                  <MenuItem value={"購買"}>購買</MenuItem>
                  <MenuItem value={"貸款"}>貸款</MenuItem>
                  <MenuItem value={"投資移民"}>投資移民</MenuItem>
                  <MenuItem value={"資產評估"}>資產評估</MenuItem>
                  <MenuItem value={""}></MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{
                marginTop: 3,
                marginBottom: 2,
                paddingLeft: 0,
                color: "#663300",
                // backgroundColor: "#CC9966",
                borderBottom: "3px dashed #663300",
                textAlign: "left",
              }}
            >
              物業資料
            </Typography>

            <Grid
              container
              columnSpacing={1}
              rowSpacing={1}
              alignItems="center"
            >
              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ textAlign: "right" }}
                >
                  委託估價物業名稱＊
                </Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <TextField
                  color="error"
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ sx: { height: 40 } }}
                  fullWidth
                  focused
                  // multiline
                  onChange={handleInputChange}
                  id="building_name"
                  name="building_name"
                  value={selectedRow.building_name || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  期／區
                </Typography>
              </Grid>
              <Grid item xs={8} md={1.8}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="building_phase"
                  name="building_phase"
                  value={selectedRow.building_phase || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  苑
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="building_estate"
                  name="building_estate"
                  value={selectedRow.building_estate || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  幢／座
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="building_tower"
                  name="building_tower"
                  value={selectedRow.building_tower || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  樓層
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextField
                  inputProps={{ maxLength: 10 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="building_floor"
                  name="building_floor"
                  value={selectedRow.building_floor || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  座
                </Typography>
              </Grid>
              <Grid item xs={8} md={1.8}>
                <TextField
                  inputProps={{ maxLength: 10 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  // fullWidth
                  onChange={handleInputChange}
                  id="building_block"
                  name="building_block"
                  value={selectedRow.building_block || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  登記面積
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} md={1}>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      "& input": {
                        textAlign: "right",
                      },
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0, max: 1000000, step: 0.001 },
                    sx: { height: 40 },
                  }}
                  focused
                  fullWidth
                  onChange={handleInputChange}
                  id="registration_area"
                  name="registration_area"
                  value={
                    selectedRow.registration_area
                      ? currencyValueFormatter(selectedRow.registration_area)
                      : // ? (selectedRow.registration_area)
                        ""
                  }
                />
              </Grid>

              <Grid item xs={4} sm={5} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "left" }}
                >
                  平方米
                </Typography>
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  標示編號
                </Typography>
              </Grid>
              <Grid item xs={8} md={1.5}>
                <TextField
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="building_code"
                  name="building_code"
                  value={selectedRow.building_code || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.5}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  使用准照發出年份
                </Typography>
              </Grid>
              <Grid item xs={8} md={5}>
                <TextField
                  inputProps={{ maxLength: 4 }}
                  InputProps={{ sx: { height: 40 } }}
                  focused
                  onChange={handleInputChange}
                  id="license_issue_year"
                  name="license_issue_year"
                  value={selectedRow.license_issue_year || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "right" }}
                >
                  備註
                </Typography>
              </Grid>
              <Grid item xs={8} md={10.8}>
                <TextField
                  color="primary"
                  // multiline
                  InputProps={{ sx: { height: 40 } }}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  focused
                  onChange={handleInputChange}
                  id="apartment_description"
                  name="apartment_description"
                  value={selectedRow.apartment_description || ""}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="green"
                  sx={{ textAlign: "right" }}
                >
                  有查屋紙
                </Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <OutlinedDiv color="success">
                  <FormControlLabel
                    label="&nbsp;&nbsp;"
                    control={
                      <Checkbox
                        color="success"
                        sx={{
                          height: "10px",
                          "&.Mui-disabled": { color: "#357a38" },
                        }}
                        onChange={handleInputChange}
                        disabled
                        inputProps={{ readOnly: true }}
                        checked={selectedRow.appendix01 === 1}
                        id="appendix01"
                        name="appendix01"
                      />
                    }
                  />
                </OutlinedDiv>
              </Grid>

              <Grid item xs={4} md={1}>
                <Typography
                  variant="body1"
                  color="green"
                  sx={{ textAlign: "right" }}
                >
                  附件數量
                </Typography>
              </Grid>
              <Grid item xs={8} md={1}>
                <TextField
                  color="success"
                  sx={{
                    "& .MuiInputBase-root": {
                      "& input": {
                        textAlign: "right",
                      },
                    },
                  }}
                  InputProps={{ readOnly: true, sx: { height: 40 } }}
                  // type='number'
                  // fullWidth
                  focused
                  onChange={handleInputChange}
                  id="pic_count"
                  name="pic_count"
                  value={selectedRow.pic_count || ""}
                />
              </Grid>

              <Grid item xs={4} md={1.5}>
                <Typography
                  variant="body1"
                  color="green"
                  sx={{ textAlign: "right" }}
                >
                  上傳附件大小(MB)
                </Typography>
              </Grid>
              <Grid item xs={8} md={6}>
                <TextField
                  color="success"
                  sx={{
                    "& .MuiInputBase-root": {
                      "& input": {
                        textAlign: "right",
                      },
                    },
                  }}
                  InputProps={{
                    sx: { height: 40, width: 100 },
                  }}
                  focused
                  onChange={handleInputChange}
                  id="modification_count"
                  name="modification_count"
                  value={
                    selectedRow.modification_count
                      ? (
                          Number(selectedRow.modification_count) / 1000000
                        ).toFixed(3)
                      : 0.0
                  }
                />
              </Grid>
            </Grid>

            {isReply && (
              <div>
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 3,
                    marginBottom: 2,
                    paddingLeft: 0,
                    color: "#663300",
                    // backgroundColor: "#CC9966",
                    borderBottom: "3px dashed #663300",
                    textAlign: "left",
                  }}
                >
                  審批結果&nbsp;&nbsp;&nbsp;(本澳物業估值默認幣種HKD，內地物業估值默認幣種CNY)
                </Typography>

                <Grid
                  container
                  columnSpacing={1}
                  rowSpacing={1}
                  alignItems="center"
                >
                  <Grid item xs={4} md={1}>
                    <Typography
                      variant="body1"
                      color="green"
                      sx={{ textAlign: "right" }}
                    >
                      {evaluationLabels[0]}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={2}>
                    <TextField
                      color="success"
                      InputProps={{ readOnly: true, sx: { height: 40 } }}
                      // type='number'
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": { textAlign: "right" },
                        },
                      }}
                      fullWidth
                      focused
                      // label={evaluationLabels[0]}
                      id="specific_value1"
                      name="specific_value1"
                      value={
                        selectedRow.specific_value1
                          ? currencyValueFormatter(selectedRow.specific_value1)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={0.8}>
                    <Typography
                      variant="body1"
                      color="green"
                      sx={{ textAlign: "right" }}
                    >
                      {evaluationLabels[1]}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={2}>
                    <TextField
                      color="success"
                      InputProps={{ readOnly: true, sx: { height: 40 } }}
                      // type='number'
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": { textAlign: "right" },
                        },
                      }}
                      fullWidth
                      focused
                      // label={evaluationLabels[1]}
                      id="specific_value2"
                      name="specific_value2"
                      value={
                        selectedRow.specific_value2
                          ? currencyValueFormatter(selectedRow.specific_value2)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={0.8}>
                    <Typography
                      variant="body1"
                      color="green"
                      sx={{ textAlign: "right" }}
                    >
                      {evaluationLabels[2]}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={2}>
                    <TextField
                      color="success"
                      InputProps={{ readOnly: true, sx: { height: 40 } }}
                      // type='number'
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": { textAlign: "right" },
                        },
                      }}
                      fullWidth
                      focused
                      // label={evaluationLabels[2]}
                      id="specific_value3"
                      name="specific_value3"
                      value={
                        selectedRow.specific_value3
                          ? currencyValueFormatter(selectedRow.specific_value3)
                          : ""
                      }
                    />
                  </Grid>

                  {displayLabel && (
                    <Grid item xs={12} md={3.2}>
                      <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        alignItems="center"
                      >
                        <Grid item xs={4} md={4}>
                          <Typography
                            variant="body1"
                            color="green"
                            sx={{ textAlign: "right" }}
                          >
                            {evaluationLabels[3]}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <TextField
                            color="success"
                            InputProps={{ readOnly: true, sx: { height: 40 } }}
                            // type='number'
                            sx={{
                              "& .MuiInputBase-root": {
                                "& input": { textAlign: "right" },
                              },
                            }}
                            fullWidth
                            focused
                            // label={evaluationLabels[3]}
                            id="specific_value4"
                            name="specific_value4"
                            value={
                              selectedRow.specific_value4
                                ? currencyValueFormatter(
                                    selectedRow.specific_value4
                                  )
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </Box>

          {isReply && (
            <Box
              component="form"
              sx={{
                flexGrow: 2,
                margin: "0px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Button
                    color="success"
                    fullWidth
                    sx={{ padding: "8px 0px" }}
                    variant="contained"
                    onClick={() => setFloor(true)}
                  >
                    多戶估價
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  {isDesktop && (
                    <Button
                      variant="contained"
                      color="warning"
                      fullWidth
                      sx={{ padding: "8px 0px" }}
                      onClick={handleCaptureClick}
                    >
                      截取畫面
                    </Button>
                  )}
                </Grid>

                <Grid item xs={3}>
                  {searchOption === "4" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{ padding: "8px 0px" }}
                      onClick={handleDownloadPDF}
                    >
                      下載結果
                    </Button>
                  )}
                </Grid>

                <Grid item xs={3}>
                  {searchOption === "4" && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ padding: "8px 0px" }}
                      onClick={handleCopyRow}
                    >
                      創建申請
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </div>

        {isEditable && (
          <div>
            <Box
              sx={{
                flexGrow: 2,
                margin: "0px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                alignItems="center"
              >
                <Grid item xs={4} md={1}>
                  <Typography
                    variant="body1"
                    color="red"
                    sx={{ textAlign: "right" }}
                  >
                    附件＊
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10.8}>
                  <TextField
                    color="error"
                    fullWidth
                    inputProps={{ maxLength: 1200 }}
                    InputProps={{ readOnly: true }}
                    multiline
                    focused
                    onChange={handleInputChange}
                    id="picture"
                    name="picture"
                    value={selectedRow.picture || ""}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        )}

        {isEditable && (
          <BasicUploadFiles
            handleUpload={handleUpload}
            clearPictures={clearPictures}
            upload_progress={upload_progress}
            setUploadProgress={set_upload_progress}
            clearAcceptedFiles={clearAcceptedFiles}
            setClearAcceptedFiles={setClearAcceptedFiles}
            setPictureEdit={setPicture}
          />
        )}

        <Box
          sx={{
            flexGrow: 2,
            margin: "0px",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <Grid container columnSpacing={1} rowSpacing={1} alignItems="center">
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                sx={{
                  bgcolor: "#5d4037",
                  color: "#ffffff",
                  padding: "8px 0px",
                  border: "3px solid #5d4037",
                  "&:hover": {
                    bgcolor: "#8d6e63",
                  },
                }}
                variant="contained"
                onClick={() => confirmMove(-1)}
              >
                上筆記錄
              </Button>
            </Grid>
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                color="secondary"
                sx={{
                  padding: "8px 0px",
                  border: "3px solid #9c27b0",
                }}
                variant="contained"
                onClick={() => confirmMove(1)}
              >
                下筆記錄
              </Button>
            </Grid>
            <Grid item xs={12 / 5}>
              {isEditable && (
                <Button
                  fullWidth
                  color="success"
                  sx={{
                    padding: "8px 0px",
                    border:
                      isSaved === false
                        ? "3px solid #FF0000"
                        : "3px solid #008800",
                  }}
                  variant="contained"
                  // onClick={(event) => {
                  //   handleSave(event, "0");
                  // }}
                  onClick={() => {
                    handleSave("0");
                  }}
                >
                  儲存申請
                </Button>
              )}
            </Grid>
            <Grid item xs={12 / 5}>
              {isEditable && (
                <Button
                  fullWidth
                  color="warning"
                  sx={{
                    padding: "8px 0px",
                    border: "3px solid #ed6c02",
                  }}
                  variant="contained"
                  // onClick={(event) => {
                  //   handleSave(event, "1");
                  // }}
                  onClick={() => {
                    handleSave("1");
                  }}
                >
                  提交申請
                </Button>
              )}
            </Grid>
            <Grid item xs={12 / 5}>
              <Button
                fullWidth
                color="error"
                sx={{ padding: "8px 0px", border: "3px solid #d32f2f" }}
                variant="contained"
                onClick={handleClose}
              >
                關閉記錄
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FormControl>

      {isReply && (
        <Floor floor={floor} setFloor={setFloor} selectedRow={selectedRow} />
      )}

      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "400px", height: "60px" }}
        >
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {alertMessage}
          </span>
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarWarning}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: "400px", height: "60px" }}
        >
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {alertMessage}
          </span>
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default Edit;
