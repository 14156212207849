// Banner.js
import React from 'react';

const Banner = () => {
  return (
    <div>
        <img src="./banner01.jpg" alt="Read Estate" style={{ width: "100%" }} />
    </div>
  );
};

export default Banner;