import React from "react";
import { TextField } from "@mui/material";

const OutlinedDiv = ({
  color = 'primary',
  children,
  label,
  fullWidth,
  height = 40,
  width,
}: any) => {
  return (
    <TextField
      color={color}
      focused
      variant="outlined"
      label={label}
      multiline
      fullWidth={fullWidth}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        // @ts-ignore 2023.10.13
        inputComponent: 'div', // Use 'div' as the input component
        sx: { height: height, width: width }
      }}
      inputProps={{ children: children }}
    />
  );
};

export default OutlinedDiv;