import React from "react";
import { Grid, Typography, Box, Stack, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { blue } from "@mui/material/colors";

// @ts-ignore
import Navigation from "./Navigation.tsx";
// @ts-ignore
import Footer from "./Footer.tsx";
// @ts-ignore
import Banner from "./Banner.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Agency() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid style={{ minHeight: "100vh" }} container margin={0}>
      <Grid item xs={12} margin={2}>
        <Navigation mode="agency" />
      </Grid>

      <Grid item xs={12} marginLeft={2} marginRight={2}>
        <Banner/>
        {/* <img src="./banner01.jpg" alt="Real Estate" style={{ width: "100%" }} /> */}
      </Grid>

      <Grid item xs={12} md={12} margin={2}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="服務範圍">
              <Tab
                label="估價服務"
                {...a11yProps(0)}
                sx={{
                  fontSize: "24px",
                  color: "#1976d2", // Set default font color
                }}
              />
              <Tab
                label="收費標準"
                {...a11yProps(1)}
                sx={{
                  fontSize: "24px",
                  color: "#1976d2", // Set default font color
                }}
              />
              <Tab
                label="表格下載"
                {...a11yProps(2)}
                sx={{
                  fontSize: "24px",
                  color: "#1976d2", // Set default font color
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              <b>估價範圍：</b>
            </Typography>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 1.5 }} // Increase line spacing
            >
              提供澳門各類型房地產物業專業評估價值服務，包括住宅、鋪位、寫字樓、廠房、別墅、停車位、地皮、全幢建築物或酒店等物業估值、租值服務。同時提供代辦理中國內地房地產估價服務。
            </Typography>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              <b>服務範圍：</b>
            </Typography>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 1.5 }} // Increase line spacing
            >
              <li>獨立單位物業評估報告書：平均3-5個工作天</li>
              <li>整幢物業或大型房產評估報告書：平均5-7個工作天</li>
              <b>注：</b>如需作現場觀察或提供更多資料方可作評估，則額外需時。
            </Typography>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              <b>澳门物業估價收費標準：</b>
            </Typography>
            <Typography
              variant="body1"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              （估值單位：港元 收費單位：澳門元）
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "25%",
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                        color: blue[500],
                        fontSize: 18,
                      }}
                    >
                      物業類別
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "28%",
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                        color: blue[500],
                        fontSize: 18,
                      }}
                    >
                      評估價值收費
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "25%",
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                        color: blue[500],
                        fontSize: 18,
                      }}
                    >
                      評估租值收費
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "22%",
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                        color: blue[500],
                        fontSize: 18,
                      }}
                    >
                      收費方式
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      有分層登記的非商業單位
                      <br />
                      (住宅/寫字樓/工業)
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      估值2億元及以下：1,000元
                      <br />
                      估值2億以上：1,500元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      1,000元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      先收1,000元訂金，餘數取報告書時收取
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      每個車位
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      300元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      300元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      一次性收取
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      有分層登記的商業單位
                      <br />
                      (舖位/商場/其他)
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      估值5,000萬元及以下： 1,500元
                      <br />
                      估值5,001萬至1億元： 2,000元
                      <br />
                      估值1億以上至1.5億元： 2,500元
                      <br />
                      估值1.5億以上至2億元： 3,000元
                      <br />
                      估值2億以上： 4,000元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      租值18萬及以下：1,500元，之後每多3萬加收200元，最高4,000元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      先收1,500元訂金，餘數取報告書時收取
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      無分層登記的物業、地皮或酒店
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      估值5,000萬元及以下： 2,000元
                      <br />
                      估值5,001萬至1億元： 4,000元
                      <br />
                      估值1億以上至1.5億元： 6,000元
                      <br />
                      估值1.5億以上至2億元： 8,000元
                      <br />
                      估值超過2億之後：估值每增加≦2,000萬元加收2,000元，最高收50,000元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      租值6萬及以下： 2,000元，之後每多6萬加收1,000元
                      最高收40,000元
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        margin: 0,
                        border: "1px solid #CCCCCC",
                      }}
                    >
                      先收2,000元訂金，餘數取報告書時收取
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              variant="body1"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              注：如需拍照或未能提供足夠的評估資料，本司可根據實際情況加收額外的費用，具體收費金額另議。
            </Typography>

            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 2.5 }} // Increase line spacing
            >
              <b>代辦理中國內地物業估價收費標準：</b>
            </Typography>
            <Typography
              variant="h5"
              margin={0}
              textAlign="left"
              sx={{ lineHeight: 1.5 }} // Increase line spacing
            >
              請聯繫我司具體諮詢。
            </Typography>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>

          <Grid
              container
              justifyContent="center" // Center the stacks horizontally
              alignItems="center" // Center the stacks vertically
              spacing={2}
              rowSpacing={5}
            >
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    href="澳门物業估價委託書.pdf"
                    download="澳门物業估價委託書.pdf"
                    sx={{ backgroundColor: "white" }}
                  >
                    <img
                      src="澳门物業估價委託書.jpg"
                      alt="澳门物業估價委託書"
                      width="300px"
                      style={{ display: "block", margin: "auto" }} // Center image within button
                    />
                  </Button>
                  <Button
                    variant="contained"
                    href="澳门物業估價委託書.pdf"
                    download="澳门物業估價委託書.pdf"
                    sx={{ fontSize: "20px", width: "320px" }}
                  >
                    澳门物業估價委託書
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    href="代辦理中國內地物業估價委託書.pdf"
                    download="代辦理中國內地物業估價委託書.pdf"
                    sx={{ backgroundColor: "white" }}
                  >
                    <img
                      src="代辦理中國內地物業估價委託書.jpg"
                      alt="代辦理中國內地物業估價委託書"
                      width="300px"
                      style={{ display: "block", margin: "auto" }} // Center image within button
                    />
                  </Button>
                  <Button
                    variant="contained"
                    href="代辦理中國內地物業估價委託書.pdf"
                    download="代辦理中國內地物業估價委託書.pdf"
                    sx={{ fontSize: "20px", width: "320px" }}
                  >
                    代辦理中國內地物業估價委託書
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Agency;
