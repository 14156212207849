/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import fileDownload from "js-file-download";
import { Dialog } from "@mui/material";
// @ts-ignore
import { baseURL, alert_error, saveCurrentTimeToLocalStorage } from "./settings.tsx";

const columns: GridColDef[] = [
  { field: "row_id", headerName: "ID", width: 60 },
//   { field: "source_code", headerName: "雲端申請編號", width: 200 },
//   { field: "target_code", headerName: "估價/回覆編號", width: 200 },
  { field: "filename", headerName: "文件名稱", width: 500 },
  // { field: 'base64_utf8', headerName: '文件內容', width: 100 },
];

type NewRow = {
  row_id: string;
  filename: string;
};

function Picture({ picture, setPicture, row_picture }: any) {
  let newRows: Array<NewRow> = [];
  const [rows, setRows] = useState(newRows);
  const [TempRowId, setTempRowId] = useState(0);

  const handleClose = (reason: any) => {
    setPicture(false);
  };

  function picture_list(row_picture: any) {
    var pictures = row_picture.split(/\r\n|\r|\n/);
    
    let newRows = pictures.map((filename: any, index: any) => {
        return {
          row_id: index + 1,
          filename: filename
        };
      });      
      setRows(newRows);    
  }

  useEffect(() => {
    console.log(13);
    if (picture === true) {
      picture_list(row_picture);
    }
  }, [picture]);

  async function download(row_id: string) {
    //  alert(row_id);
    let selectedRows = rows.filter(
      (row) => parseInt(row.row_id) === parseInt(row_id)
    );
    if (selectedRows.length === 0) {
      alert("找不到選取的記錄(" + row_id + ")。");
      return;
    }
    let filename = selectedRows[0].filename;

    axios({
      url: baseURL + "/picture/" + filename,
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      responseType: "blob",
    })
      .then((res) => {
        fileDownload(res.data, filename);
        alert("上傳附件已經下載：\r"+filename);
      })
      .catch((error) => {
        if (error.response) {
          // Handle error response
          return new Response(error.response.data).text().then((errorData) => {
            alert(JSON.parse(errorData).detail);
          });
        } else if (error.request) {
          // Handle request error
          console.error("Request Error:", error.request);
        } else {
          // Other errors
          console.error("Error:", error.message);
        }
      });
  }

  return (
    <Dialog fullWidth maxWidth="xl" open={picture}>
      <Box
        sx={{ flexGrow: 2, margin: "20px", padding: "0px" }}
      >
        <Grid container spacing={2}>
          <Grid item md={8} xs={6} sx={{ mb: 0 }}>
            已經上傳的附件 - 點擊選取，按鈕下載
          </Grid>
          <Grid item md={2} xs={3} sx={{ mb: 1 }}>
            <Button fullWidth color='error' variant="contained" onClick={()=>download(TempRowId.toString())}>
              下載
            </Button>
          </Grid>
          <Grid item md={2} xs={3} sx={{ mb: 1 }}>
            <Button fullWidth variant="contained" onClick={handleClose}>
              離開
            </Button>
          </Grid>
        </Grid>

        <DataGrid
          getRowId={(row) => row.row_id}
          rows={rows}
          columns={columns}
          sx={{ height: 450 }}
          rowHeight={30}
          headerHeight={40}
          initialState={{
            pagination: {
              pageSize: 10,
            },
          }}
          rowsPerPageOptions={[10, 100]}
          // checkboxSelection
          onSelectionModelChange={(ids) => {
            if (ids.length === 0) {
              return;
            }
            setTempRowId(Number(ids[0]));
            // let temp_row_id = Number(ids[0]);
            // download(temp_row_id.toString());
          }}
        />
      </Box>
    </Dialog>
  );
}

export default Picture;
