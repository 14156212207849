import React, { useState } from "react";
// import { useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

function Navigation({ mode }: any) {
  // const theme = useTheme();

  const [open, setOpen] = useState(false);
  const isXsScreen = !useMediaQuery("(min-width:900px)");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={6}
        md={3}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          color="primary"
          component={Link}
          to="/"
          style={{
            textAlign: "left",
            wordBreak: "keep-all",
            textDecoration: "none",
            fontSize: "30px",
          }}
        >
          德記置業有限公司
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        md={9}
        // sx={{ textAlign: "right", alignItems: "center", }}
        sx={{ textAlign: "right", alignItems: "center" }}
      >
        {isXsScreen ? (
          <>
            <Typography
              component="button"
              color="primary"
              onClick={handleDrawerOpen}
              sx={{
                border: "none",
                // border: "5px dotted blue",
                background: "transparent",
                fontSize: "45px",
                marginTop: "-10px",
                // marginRight: "-30px",
                paddingRight: 0,
              }}
            >
              ☰
            </Typography>
            <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
              <Box
                role="presentation"
                onClick={handleDrawerClose}
                onKeyDown={handleDrawerClose}
              >
                <List>
                  <ListItem
                    component={Link}
                    to="/"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "index" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      主頁
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/agency"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "agency" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      服務範圍
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/main"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "main" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      估價系統
                    </Typography>
                  </ListItem>

                  {/* <ListItem
                    component={Link}
                    to="/price"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "price" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      估價收價
                    </Typography>
                  </ListItem> */}

                  <ListItem
                    component={Link}
                    to="/about"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "about" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      關於德記
                    </Typography>
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/contact"
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={mode === "contact" ? "error" : "primary"}
                      variant="h6"
                      sx={{ marginRight: "1rem" }}
                    >
                      聯絡我們
                    </Typography>
                  </ListItem>

                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            <Typography
              color={mode === "index" ? "error" : "primary"}
              component={Link}
              to="/"
              variant="h6"
              sx={{ marginRight: "1rem", textDecoration: "none" }}
            >
              主頁&nbsp;
            </Typography>
            <Typography
              color={mode === "agency" ? "error" : "primary"}
              component={Link}
              to="/agency"
              variant="h6"
              sx={{ marginRight: "1rem", textDecoration: "none" }}
            >
              服務範圍&nbsp;
            </Typography>
            <Typography
              color={mode === "main" ? "error" : "primary"}
              component={Link}
              to="/main"
              variant="h6"
              sx={{ marginRight: "1rem", textDecoration: "none" }}
            >
              估價系統&nbsp;
            </Typography>
            <Typography
              color={mode === "about" ? "error" : "primary"}
              component={Link}
              to="/about"
              variant="h6"
              sx={{ marginRight: "1rem", textDecoration: "none" }}
            >
              關於德記&nbsp;
            </Typography>
            <Typography
              color={mode === "contact" ? "error" : "primary"}
              component={Link}
              to="/contact"
              variant="h6"
              sx={{ marginRight: "0rem", textDecoration: "none" }}
            >
              聯絡我們&nbsp;
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Navigation;
