/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
// @ts-ignore
import Login from "./Login.tsx";
// @ts-ignore
import Navigation from "./Navigation.tsx";
// @ts-ignore
import Footer from "./Footer.tsx";
// @ts-ignore
import Banner from "./Banner.tsx";

function Main() {
  return (
    <Grid style={{ minHeight: "100vh" }} container margin={0}>
      <Grid item xs={12} margin={2}>
        <Navigation mode="main" />
      </Grid>
      <Grid item xs={12} marginLeft={2} marginRight={2}>
        <Banner/>
        {/* <img src="./banner01.jpg" alt="Read Estate" style={{ width: "100%" }} /> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-around">
          <Grid item xs={12} md={4}>
            <Typography
              style={{
                textAlign: "left",
                paddingLeft: "20px",
                paddingTop: "30px",
                fontSize: "32px",
              }}
            >
              德記估價服務系統
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                paddingLeft: "20px",
                paddingBottom: "20px",
                fontSize: "20px",
              }}
            >
              Service System
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{ paddingLeft: "20px", paddingBottom: "60px" }}
          >
            <Login />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Main;
