/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid"; // import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Grid, Button } from "@mui/material";
import { Dialog } from "@mui/material";

const columns: GridColDef[] = [
  { field: "row_id", headerName: "行號", width: 80 },
//   { field: "field", headerName: "欄目名稱", width: 120 },
  { field: "headerName", headerName: "欄目標籤", width: 180 },
  {
    field: "width",
    headerName: "欄目寬度",
    width: 120,
    editable: true,
    valueParser: (value) => {
      const parsedValue = parseInt(value, 10);
      if (isNaN(parsedValue) || parsedValue < 0) {
        return null; // Invalid value, return null to indicate parsing error
      }
      return parsedValue;
    },
  },
];

type NewRow = {
  row_id: number;
  field: string;
  headerName: string;
  width: number;
};

function ColSearch({ col_search, setColSearch, col_width, handleUpdateColWidth }: any) {
  let newRows: Array<NewRow> = [];
  const [rows, setRows] = useState(newRows);

  const handleClose = (reason: any) => {
    setColSearch(false);
  };

  console.log("col_width");
  console.log(col_width);

  function col_search_list(source_code: any) {
    newRows = [
      { row_id: 1, field: "status", headerName: "狀態", width: col_width[0] },
      { row_id: 2, field: "actions", headerName: "操作", width: col_width[1] },
      { row_id: 3, field: "consign_date", headerName: "委託日期", width: col_width[2] },
      { row_id: 4, field: "building_code", headerName: "標示編號", width: col_width[3] },

      { row_id: 5, field: "building_name", headerName: "物業名稱", width: col_width[4] },
      { row_id: 6, field: "building_phase", headerName: "期/區", width: col_width[5] },
      { row_id: 7, field: "building_estate", headerName: "苑", width: col_width[6] },
      { row_id: 8, field: "building_tower", headerName: "棟/座", width: col_width[7] },
      { row_id: 9, field: "building_floor", headerName: "樓", width: col_width[8] },
      { row_id: 10, field: "building_block", headerName: "座", width: col_width[9] },

      {
        row_id: 11,
        field: "registration_area",
        headerName: "面積（平方米）",
        width: col_width[10],
      },

      { row_id: 12, field: "specific_value1", headerName: "估值", width: col_width[11] },
      { row_id: 13, field: "specific_value2", headerName: "租值", width: col_width[12] },
      {
        row_id: 14,
        field: "specific_value3",
        headerName: "重置價",
        width: col_width[13],
      },
      {
        row_id: 15,
        field: "specific_value4",
        headerName: "迫銷價",
        width: col_width[14],
      },
      { row_id: 16, field: "final_fee", headerName: "估價費MOP", width: col_width[15] },

      {
        row_id: 17,
        field: "conversion_user",
        headerName: "經辦人員",
        width: col_width[16],
      },
      {
        row_id: 18,
        field: "building_code_str",
        headerName: "申請編號/回覆編號",
        width: col_width[17],
      },
      {
        row_id: 19,
        field: "consigner_company",
        headerName: "委託公司",
        width: col_width[18],
      },
      {
        row_id: 20,
        field: "consigner_branch",
        headerName: "委託部門/分行",
        width: col_width[19],
      },
      { row_id: 21, field: "client_name", headerName: "客戶名稱", width: col_width[20] },
      {
        row_id: 22,
        field: "application_user",
        headerName: "申請人員",
        width: col_width[21],
      },
    ];
    setRows(newRows);
  }

  useEffect(() => {
    console.log(11);
    if (col_search === true) {
      col_search_list(col_search);
    }
  }, [col_search]);

  return (
    <Dialog fullWidth maxWidth="xs" open={col_search}>
      <Box sx={{ flexGrow: 2, margin: "20px", padding: "0px" }}>
        <Grid container spacing={2}>
          <Grid item md={10} xs={9} sx={{ mb: 0 }}>
            欄目寬度設定
          </Grid>
          {/* <Grid item md={2} xs={3} sx={{ mb: 1 }}>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => download(TempRowId.toString())}
            >
              設定
            </Button>
          </Grid> */}
          <Grid item md={2} xs={3} sx={{ mb: 1 }}>
            <Button fullWidth variant="contained" onClick={handleClose}>
              離開
            </Button>
          </Grid>
        </Grid>

        <DataGrid
          onCellEditCommit={(params) => {
            if (params.value !== null) {
                console.log(params.value);
                handleUpdateColWidth(params.id, params.value);
            } else {
                console.log('col_width null value');
            }
          }}
          getRowId={(row) => row.row_id}
          rows={rows}
          columns={columns}
          sx={{ height: 450 }}
          rowHeight={30}
          headerHeight={40}
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[10, 25]}
          // checkboxSelection
          //   onSelectionModelChange={(ids) => {
          //     if (ids.length === 0) {
          //       return;
          //     }
          //     setTempRowId(Number(ids[0]));
          //     // let temp_row_id = Number(ids[0]);
          //     // download(temp_row_id.toString());
          //   }}
        />
      </Box>
    </Dialog>
  );
}

export default ColSearch;
