import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Grid, Typography, Box } from "@mui/material";
// @ts-ignore
import { MAX_PIC_COUNT, MAX_UPLOAD_SIZE } from "./settings.tsx";
// @ts-ignore
import CircularProgress from "@mui/material/CircularProgress";

function BasicUploadFiles({
  handleUpload,
  clearPictures,
  upload_progress,
  setUploadProgress,
  clearAcceptedFiles,
  setClearAcceptedFiles,
  setPictureEdit,
}: any) {
  interface FileWithPath extends File {
    path?: string;
  }

  const [files, setFiles] = useState<Array<FileWithPath>>([]);

  const onDrop = useCallback((acceptedFiles: Array<FileWithPath>) => {
    setFiles(acceptedFiles);
  }, []);

  const clearFiles = () => setFiles([]);

  const acceptedFileTypes = {
    "application/msword": [".doc", ".docx"],
    "application/vnd.ms-excel": [".xls", ".xlsx"],
    "application/pdf": [".pdf"],
    "image/jpeg": [".jpg"],
    "image/tiff": [".tif"],
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_PIC_COUNT,
    accept: acceptedFileTypes,
    onDrop,
  });

  useEffect(() => {
    if (clearAcceptedFiles === true) {
      clearFiles();
      setClearAcceptedFiles(false); // reset the status
      setUploadProgress(0);
    }
  }, [clearAcceptedFiles]);

  const fileslist: any = files.map((file) => (
    <li key={file.path}>
      {file.path} - {(file.size / 1000000).toFixed(2)} MB
    </li>
  ));

  function OpenPictureList(){
    setPictureEdit(true);
  }

  function clearPictureList() {
    if (
      window.confirm(
        "清除已經上傳的附件清單？如果清除，需要重新上傳，才能寫入欄目。"
      ) === true
    ) {
      clearPictures();
    }
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    console.log("handleSubmit");
    if (files.length === 0) {
      alert("請先選取文件。");
      return;
    }

    var total_size = 0;
    // acceptedFiles.map((file) => (total_size = total_size + file.size));
    files.map((file) => (total_size = total_size + file.size));
    const size_in_MB = Math.round(total_size / 1000000);
    if (size_in_MB > MAX_UPLOAD_SIZE) {
      alert(
        "附件大小：" +
          size_in_MB.toString() +
          "MB，超過" +
          MAX_UPLOAD_SIZE.toString() +
          "MB，不能上傳。"
      );
      return;
    }
    // handleUpload(acceptedFiles);
    handleUpload(files);
  }

  return (
    <form>
      <section
        style={{
          background: "#bbdefb",
          margin: "0 20px 0 20px",
          padding: "20px 20px 20px 20px",
          border: "solid 0px #2979ff",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            border: "0px solid #2979ff",
            padding: "10px",
            background: "#fff",
            borderRadius: "5px",
            overflow: "auto",
            height: "300px",
            fontSize: "24px",
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p
            style={{
              color: "#1976d2",
              lineHeight: "1.5",
              padding: "10px",
              margin: "0",
            }}
          >
            拖放附件至此，或者{" "}
            <Button
              sx={{
                bgcolor: "#263238",
                color: "#ffffff",
              }}
              variant="contained"
            >
              點擊選取附件
            </Button>{" "}
            ，然後上傳。
            <br />
            目前祇接受doc、docx、xls、xlsx、jpg、pdf、tif。附件數量不大於12個，所有附件大小合計不大於5M。
          </p>
        </div>{" "}
        <aside>
          <h4 style={{ color: "#1976d2" }}>已選附件清單</h4>
          <ul
            style={{
              listStyleType: "decimal",
              padding: "0",
              marginTop: "-10px",
              marginRight: "0",
              marginLeft: "20px",
              marginBottom: "20px",
            }}
          >
            {fileslist}
          </ul>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4} md={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                // color="info"
                // sx={{ bgcolor: "#01579b", color: "#ffffff" }}
                sx={{ bgcolor: "#ad1457", color: "#ffffff" }}
              >
                上傳附件
              </Button>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              alignItems="center"
              justifyContent="center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={upload_progress}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {upload_progress}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Button
                sx={{ bgcolor: "#424242", color: "#ffffff" }}
                fullWidth
                variant="contained"
                onClick={OpenPictureList}
              >
                下載附件
              </Button>
            </Grid>
            <Grid item xs={3} md={3}>
              <Button
                sx={{ bgcolor: "#01579b", color: "#ffffff" }}
                fullWidth
                variant="contained"
                onClick={clearPictureList}
              >
                清空附件
              </Button>
            </Grid>
          </Grid>
        </aside>
        <aside></aside>
      </section>
    </form>
  );
}

export default BasicUploadFiles;
