import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  Button,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import axios from "axios";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "./react-simple-captcha.js";

// @ts-ignore
import {
  removeSQLInjectionKeywords,
  alert_error,
  baseURL,
  DEVELOPMENT,
  timeformat,
  yearformat,
  validTelNumber,
  // @ts-ignore
} from "./settings.tsx";

// @ts-ignore
import OutlinedDiv from "./OutlinedDiv.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Apply() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const defaultApply = {
    consigner: "",
    gender: "",
    phone: "",
    building_address: "",
    building_type: "",
    document_code: "",
    captcha: "", // "跳過檢驗",
  };

  const [formValues, setFormValues] = useState(defaultApply);

  function error_action(error: any) {
    if (error.response.status === 429) {
      alert("提交太頻繁。");
    } else {
      alert_error(error);
    }
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let new_value;
    new_value = removeSQLInjectionKeywords(value);
    setFormValues({
      ...formValues,
      [name]: new_value,
    });
  };

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  const submit_apply = () => {
    // event.preventDefault();

    let validation_string = "";

    if (DEVELOPMENT === false) {
      if (validateCaptcha(formValues.captcha) === true) {
        // alert('校驗碼正確 Captcha Matched');
        loadCaptchaEnginge(4);
      } else {
        // alert("校驗碼不正確，請重新輸入。");
        // return;
        validation_string =
          validation_string + "•「校驗碼」不正確，請重新輸入。\n";
      }
    }

    var form_data = new FormData();

    let field_string = "";
    for (var key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        const value = formValues[key as keyof typeof formValues];
        if (value !== null && value !== "") {
          form_data.append(key, value);
        }
      }
    }

    if (
      form_data.get("consigner") === null ||
      String(form_data.get("consigner")).trim() === ""
    ) {
      field_string =
        field_string === ""
          ? field_string + "「委託人姓名」"
          : field_string + "、「委託人姓名」";
    }

    if (
      form_data.get("gender") === null ||
      String(form_data.get("gender")).trim() === ""
    ) {
      field_string =
        field_string === ""
          ? field_string + "「性別」"
          : field_string + "、「性別」";
    }

    if (
      form_data.get("phone") === null ||
      String(form_data.get("phone")).trim() === ""
    ) {
      field_string =
        field_string === ""
          ? field_string + "「委託人電話」"
          : field_string + "、「委託人電話」";
    }

    if (
      form_data.get("building_address") === null ||
      String(form_data.get("building_address")).trim() === ""
    ) {
      field_string =
        field_string === ""
          ? field_string + "「申請估價物業名稱/地址」"
          : field_string + "、「申請估價物業名稱/地址」";
    }

    if (field_string !== "") {
      validation_string =
        validation_string + "•以下欄目不能留空：" + field_string + "\n";
    }

    const phone = String(form_data.get("phone") || "");
    if (!validTelNumber(phone)) {
      validation_string =
        validation_string +
        "•「委託人電話」容許數字、括號()、連接符-，要求8位數字或以上。\n";
    }

    const document_code = form_data.get("document_code") || "";
    if (document_code !== "" && document_code.length < 5) {
      validation_string = validation_string + "•「查屋紙編號」不能短於5位。\n";
    }

    const building_address = form_data.get("building_address") || "";
    if (building_address.length < 10) {
      validation_string =
        validation_string + "•「申請估價物業名稱/地址」不能短於10位。\n";
    }

    if (validation_string !== "") {
      validation_string = "欄目校驗錯誤，請修正：\n" + validation_string;
      alert(validation_string);
      return;
    }

    axios({
      method: "POST",
      url: baseURL + "/apply",
      data: form_data,
    })
      .then((response) => {
        const apply = response.data.list;
        const delay = Number(response.data.delay);
        if (delay > 0 && delay < 60) {
          alert(
            "你於" +
              delay.toString() +
              "分鐘之前，已經成功提交相同的資料，時間仍以之前的申請為準。"
          );
        }
        if (apply !== null) {
          alert(
            "閣下已成功提交，工作人員會於一個工作天內聯絡您，感謝您的支持！"
          );
        } else {
          alert();
        }
      })
      .catch((error) => {
        error_action(error);
      });
  };

  return (
    <FormControl fullWidth>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="服務範圍">
            <Tab
              label="聯絡我們"
              {...a11yProps(0)}
              sx={{
                fontSize: "24px",
                color: "#1976d2", // Set default font color
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid
            container
            marginTop={0}
            justifyItems="center"
            alignItems="center"
            columnSpacing={1}
            rowSpacing={1}
            sx={{ maxWidth: 600, width: "100%", margin: "0 auto", }}
          >
              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  姓名*
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="left">
                <TextField
                  focused
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 50 } }}
                  value={formValues.consigner}
                  onChange={handleInputChange}
                  // label="委託人姓名*"
                  name="consigner"
                />
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  性別*
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="left">
                <OutlinedDiv
                  // label="性別*"
                  fullWidth
                  color="primary"
                  height={50}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="性別"
                      name="gender"
                      value={formValues.gender}
                      onChange={handleInputChange}
                      row
                    >
                      <FormControlLabel
                        value="先生"
                        control={<Radio />}
                        label="先生"
                      />
                      <FormControlLabel
                        value="女士"
                        control={<Radio />}
                        label="女士"
                      />
                    </RadioGroup>
                  </FormControl>
                </OutlinedDiv>
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  電話*
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="center">
                <TextField
                  focused
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 50 } }}
                  value={formValues.phone}
                  onChange={handleInputChange}
                  // label="委託人電話*"
                  name="phone"
                />
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  物業名稱
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="center">
                <TextField
                  multiline
                  focused
                  fullWidth
                  inputProps={{ maxLength: 500 }}
                  //   InputProps={{ sx: { height: 40 } }}
                  value={formValues.building_address}
                  onChange={handleInputChange}
                  // label="申請估價物業名稱/地址(多行)*"
                  name="building_address"
                />
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  物業類型
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="left">
                <TextField
                  inputProps={{ maxLength: 10 }}
                  InputProps={{ sx: { height: 50, textAlign: "left" } }}
                  select
                  fullWidth
                  focused
                  onChange={handleInputChange}
                  // label="物業類型"
                  name="building_type"
                  value={formValues.building_type}
                >
                  <MenuItem value={"居住"}>居住</MenuItem>
                  <MenuItem value={"辦公室"}>辦公室</MenuItem>
                  <MenuItem value={"工業/廠房"}>工業/廠房</MenuItem>
                  <MenuItem value={"車位"}>車位</MenuItem>
                  <MenuItem value={"其他"}>其他</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  標示編號
                </Typography>
              </Grid>
              <Grid item xs={9} md={9} alignItems="center">
                <TextField
                  focused
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ sx: { height: 50 } }}
                  value={formValues.document_code}
                  onChange={handleInputChange}
                  // label="查屋紙編號"
                  name="document_code"
                />
              </Grid>

              <Grid item xs={3} md={3} alignItems="left">
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  驗證碼*
                </Typography>
              </Grid>
              <Grid item xs={9} md={9}>
                <TextField
                  focused
                  fullWidth
                  InputProps={{ sx: { height: 50 } }}
                  id="captcha"
                  name="captcha"
                  // label="驗證碼*"
                  value={formValues.captcha}
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={12} border={0} style={{ margin: "0px" }}>
                <LoadCanvasTemplate />
              </Grid>

              <Grid item xs={12} md={12} alignItems="center">
                <Button
                  sx={{
                    backgroundColor: "primary",
                    color: "#FFFFFF",
                    height: "50px",
                  }}
                  style={{ fontSize: 20, width: "50%" }}
                  onClick={() => {
                    submit_apply();
                  }}
                  variant="contained"
                >
                  確認提交
                </Button>
              </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </FormControl>
  );
}

export default Apply;
