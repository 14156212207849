/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import { Dialog } from "@mui/material";
// @ts-ignore
import { baseURL, alert_error } from "./settings.tsx";
// import { useNavigate } from "react-router-dom";

const defaultValues = {
  oldpass: "", // timothy
  password: "", // timothy
  retype: "", // teng1234
  // captcha: "跳過檢驗",
};

function ChangePassword({ changePassword, setChangePassword }: any) {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    setFormValues(defaultValues);
  }, [changePassword]); //  2024-02-26

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClose = (reason: any) => {
    setChangePassword(false);
    const allow_change_password_temp = localStorage.getItem("allow_change_password") || "";
    if (allow_change_password_temp === "1"){
      localStorage.removeItem('access_token');
      navigate('/main');
    }
  };

  function checkPassword(password: any) {
    // Check if the password contains at least one capitalized letter, one uncapitalized letter, and one number
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/;
    return regex.test(password);
  }

  function change_password(event: any) {

    var error_message = "";

    if (formValues.oldpass.length < 8 || formValues.oldpass.length > 50) {
      error_message = error_message + "舊密碼長度至少8位，最多50位。";
      // alert("舊密碼長度至少8位，最多50位。");
      // return;
    }
    if (checkPassword(formValues.password) === false) {
      error_message = error_message + "\n新密碼至少包含大寫、小寫、數字。";
      // alert("新密碼至少包含大寫、小寫、數字。");
      // return;
    }
    if (formValues.password.length < 8 || formValues.password.length > 50) {
      error_message = error_message + "\n新密碼長度至少8位，最多50位。";
      // alert("新密碼長度至少8位，最多50位。");
      // return;
    }
    if (formValues.oldpass === formValues.password) {
      error_message = error_message + "\n新舊密碼不能相同。";
      // alert("新舊密碼不能相同。");
      // return;
    }
    if (formValues.password !== formValues.retype) {
      error_message = error_message + "\n再輸入與新密碼不一致。";
      // alert("再輸入與新密碼不一致。");
      // return;
    }

    if (error_message !== "") {
      alert(error_message);
      return
    }

    event.preventDefault();

    var form_data = new FormData();
    form_data.append("oldpass", formValues["oldpass"]);
    form_data.append("password", formValues["password"]);

    axios({
      url: baseURL + "/change_password",
      method: "POST",
      data: form_data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        // console.log(response.data);
        // alert(response.status + ' - ' + response.data.detail);
        alert(response.data.list.detail);
        setChangePassword(false); // close it
        localStorage.removeItem('access_token'); // 2024.04.10 re-login at once
        navigate('/main');
        })
      .catch((error) => {
        alert_error(error);
        // console.error(error);
        // alert(error.response.status + ' - ' + error.response.data.detail);
      });
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={changePassword}>
      <Box
        // component="form"
        // onSubmit={handleSave}
        sx={{ flexGrow: 2, margin: "20px", padding: "0px" }}
      >
        <Grid container spacing={2}>
          <Grid item md={10} sx={{ mb: 0 }}>
            修改密碼
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <TextField
              focused
              id="oldpass"
              name="oldpass"
              label="舊密碼"
              fullWidth
              value={formValues.oldpass}
              // helperText="Username or email."
              variant="outlined"
              type="password"
              InputProps={{ sx: { height: 40 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <TextField
              focused
              id="password"
              name="password"
              label="新密碼"
              fullWidth
              value={formValues.password}
              // helperText="Username or email."
              variant="outlined"
              type="password"
              InputProps={{ sx: { height: 40 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={12} alignItems="center">
            <TextField
              focused
              id="retype"
              name="retype"
              label="再輸入"
              fullWidth
              value={formValues.retype}
              // helperText="Password not seen."
              type="password"
              variant="outlined"
              InputProps={{ sx: { height: 40 } }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Button fullWidth variant="contained" onClick={change_password}>
              提交
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button fullWidth variant="contained" onClick={handleClose}>
              返回
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>
              修改密碼規則：
            </Typography>
            <Typography>
            • 舊密碼、新密碼的長度至少8位，最多50位。
            </Typography>
            <Typography>
            • 新密碼至少包含大寫、小寫、數字。
            </Typography>
            <Typography>
            • 新舊密碼不能相同。
            </Typography>
            <Typography>
            • 再輸入與新密碼完全一致。
            </Typography>

          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default ChangePassword;
