/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { Dialog } from "@mui/material";
// @ts-ignore
import { currencyValueFormatter } from "./settings.tsx";

const columns: GridColDef[] = [
  { field: "row_id", headerName: "行號", width: 60, align: "right", headerAlign: 'center' },
  // { field: 'tower', headerName: '樓棟', width: 60  },
  { field: "floor", headerName: "樓層", width: 80, align: "center", headerAlign: 'center' },
  { field: "block", headerName: "座", width: 80, align: "center", headerAlign: 'center' },
  {
    field: "registration_area",
    headerName: "面積(平方米)",
    width: 120,
    valueFormatter: ({ value }) => currencyValueFormatter(value),
    align: "right",
    headerAlign: 'center',
  },
  // { field: 'saleable_area', headerName: '實用面積', width: 200  },
  {
    field: "final_value",
    headerName: "估值",
    width: 120,
    valueFormatter: ({ value }) => currencyValueFormatter(value),
    align: "right",
    headerAlign: 'center',
  },
  {
    field: "rental_value",
    headerName: "租值",
    width: 120,
    valueFormatter: ({ value }) => currencyValueFormatter(value),
    align: "right",
    headerAlign: 'center',
  },
  {
    field: "rebuild_value",
    headerName: "重置價",
    width: 120,
    valueFormatter: ({ value }) => currencyValueFormatter(value),
    align: "right",
    headerAlign: 'center',
  },
  {
    field: "promote_value",
    headerName: "迫銷價",
    width: 120,
    valueFormatter: ({ value }) => currencyValueFormatter(value),
    align: "right",
    headerAlign: 'center',
  },
];

type NewRow = {
  row_id: number;
  tower: string;
  floor: string;
  block: string;
  registration_area: number;
  saleable_area: number;
  rebuild_value: number;
  final_value: number;
  evaluation_fee: number;
  promote_value: number;
};

function Floor({ floor, setFloor, selectedRow }: any) {
  let newRows: Array<NewRow> = [];
  const [rows, setRows] = useState(newRows);

  const handleClose = (reason: any) => {
    setFloor(false);
  };

  const listToClipboard = () => {
    // const rows = rows;
    // const columns = gridData.columns;

    const dataString =
      columns.map((column) => column.headerName).join("\t") + "\n"; // Header row

    // const dataRows = rows
    //   .map(
    //     (row) =>
    //       columns
    //         .map((column) => row[column.field])
    //         .join("\t")
    //         .replace(/(\r\n|\n|\r)/gm, "") // Remove newlines within cells
    //   )
    //   .join("\n");

    const dataRows = rows
      .map(
        (row) =>
          columns
            .map((column) => row[column.field as keyof typeof row])
            .join("\t")
            .replace(/(\r\n|\n|\r)/gm, "") // Remove newlines within cells
      )
      .join("\n");

    const dataToCopy = dataString + dataRows;

    copyToClipboard(dataToCopy);
  };

  function copyToClipboard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        alert("清單已經匯出至剪貼板，可以粘貼到Excel或Word等其他軟件。");
      })
      .catch(function (error) {
        alert("無法覆制至剪貼板: " + error);
      });
  }

  function floor_list() {
    var field_list: Array<string> = [];
    const BuildType = selectedRow.code_type.substring(0, 1);
    // console.log(BuildType);
    field_list.push("row_id");
    field_list.push("tower");
    field_list.push("floor");
    field_list.push("block");
    field_list.push("registration_area");
    field_list.push("saleable_area");
    field_list.push("gross_area");

    if ("MC".indexOf(BuildType) >= 0) {
      field_list.push("terrace_area");
      field_list.push("terrace_ratio");
      field_list.push("view_condition");
    }

    // L only
    if ("L".indexOf(BuildType) >= 0) {
      field_list.push("width");
      field_list.push("pillar");
      field_list.push("shape");
      field_list.push("height");
      field_list.push("width_adjustment");
      field_list.push("pillar_adjustment");
      field_list.push("shape_adjustment");
      field_list.push("height_adjustment");
    }

    field_list.push("impression_ddjustment");

    if ("MC".indexOf(BuildType) >= 0) {
      field_list.push("view_adjustment");
      field_list.push("floor_adjustment");
      field_list.push("area_adjustment");
      field_list.push("basic_rate");
      field_list.push("adjusted_rate");
    } else if ("FP".indexOf(BuildType) >= 0) {
      field_list.push("floor_adjustment");
      field_list.push("area_adjustment");
      field_list.push("basic_rate");
      field_list.push("adjusted_rate");
    }

    field_list.push("initial_value");
    field_list.push("rebuild_value");
    field_list.push("final_value");
    field_list.push("evaluation_fee");

    if ("MF".indexOf(BuildType) >= 0) {
      field_list.push("usable_carpark");
    }

    field_list.push("promote_rate");
    field_list.push("promote_value");
    //
    // console.log('field_list');
    // console.log(field_list);

    let field_number: number[] = [];
    field_number[0] = field_list.indexOf("row_id");
    field_number[1] = field_list.indexOf("tower");
    field_number[2] = field_list.indexOf("floor");
    field_number[3] = field_list.indexOf("block");
    field_number[4] = field_list.indexOf("registration_area");
    field_number[5] = field_list.indexOf("saleable_area");
    field_number[6] = field_list.indexOf("rebuild_value");
    field_number[7] = field_list.indexOf("final_value");
    field_number[8] = field_list.indexOf("evaluation_fee");
    field_number[9] = field_list.indexOf("promote_value");
    // console.log(field_number);

    // copied from EXE.calculation/Datamodule, 2023.12.13
    // ' Number Tower Floor Block registration_area saleable_area '
    // +' rebuild_value final_value evaluation_fee promote_value '

    let floor_items: Array<string> = [];
    if (selectedRow.floor) {
      // floor_items = selectedRow.floor.split("\n\r");
      floor_items = selectedRow.floor.split("\r\n"); // 2024.04.25
    }
    // console.log(floor_items);

    floor_items.forEach((element) => {
      let fields = element.split("\t");
      // console.log(fields);

      let newRow: NewRow = {
        row_id: Number(fields[field_number[0]]),
        tower: String(fields[field_number[1]]),
        floor: String(fields[field_number[2]]),
        block: String(fields[field_number[3]]),
        registration_area: Number(fields[field_number[4]]),
        saleable_area: Number(fields[field_number[5]]),
        rebuild_value: Number(fields[field_number[6]]),
        final_value: Number(fields[field_number[7]]),
        evaluation_fee: Number(fields[field_number[8]]),
        promote_value: Number(fields[field_number[9]]),
      };
      newRows.push(newRow);
    });
    setRows(newRows);
    // console.log(newRows);

    // ' Number Tower Floor Block registration_area saleable_area '
    // +' rebuild_value final_value evaluation_fee promote_value '
  }

  useEffect(() => {
    if (floor === true) {
      floor_list();
    }
    // eslint-disable-next-line
  }, [floor]);

  return (
    <Dialog fullWidth maxWidth="md" open={floor}>
      <Box sx={{ flexGrow: 2, margin: "20px", padding: "0px" }}>
        <Grid container spacing={2}>
          <Grid item md={8} sx={{ mb: 0 }}>
            多戶估價
          </Grid>
          <Grid item md={2} sx={{ mb: 1 }}>
            <Button fullWidth variant="contained" onClick={listToClipboard}>
              匯出清單
            </Button>
          </Grid>
          <Grid item md={2} sx={{ mb: 1 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              color="error"
            >
              關閉清單
            </Button>
          </Grid>
        </Grid>

        <DataGrid
          getRowId={(row) => row.row_id}
          rows={rows}
          columns={columns}
          sx={{ height: 550 }}
          headerHeight={40}
          rowHeight={30}
          initialState={{
            pagination: {
              pageSize: 15,
            },
          }}
          rowsPerPageOptions={[15, 100]}
        />
      </Box>
    </Dialog>
  );
}

export default Floor;
