import React from "react";
import { Grid, } from "@mui/material";
// @ts-ignore
import Navigation from "./Navigation.tsx";
// @ts-ignore
import Footer from './Footer.tsx';
// @ts-ignore
import Apply from './Apply.tsx';
// @ts-ignore
import Banner from "./Banner.tsx";

function Contact() {
  return (
    <Grid style={{ minHeight: "100vh" }} container margin={0}>
      <Grid item xs={12} margin={2}>
        <Navigation mode="contact"/>
      </Grid>
      <Grid item xs={12} marginLeft={2} marginRight={2}>
        <Banner/>
        {/* <img src="./banner01.jpg" alt="Real Estate" style={{ width: "100%" }} /> */}
      </Grid>
      <Grid item xs={12} margin={2}>
        <Apply/>
      </Grid>
      <Footer/>
    </Grid>
  );
}

export default Contact;
