import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Search from "./Search.tsx";
import Main from "./Main.tsx";
import About from "./About.tsx";
import Contact from "./Contact.tsx";
// import Price from "./Price.tsx";
import Index from "./Index.tsx";
import Agency from "./Agency.tsx";

function App() { // 2024-02-23

  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();
    event.returnValue = '';
  });

  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/main" element={<Main />} />
          <Route path="/agency" element={<Agency />} />
          <Route path="/search" element={<Search />} />
          {/* <Route path="/price" element={<Price />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
