import React from "react";
import { Grid, Typography, Button } from "@mui/material";
// @ts-ignore
import Navigation from "./Navigation.tsx";
// @ts-ignore
import Footer from "./Footer.tsx";
// @ts-ignore
import Banner from "./Banner.tsx";

function Index() {
  return (
    <Grid style={{ minHeight: "100vh" }} container margin={0}>
      <Grid item xs={12} margin={2}>
        <Navigation mode="index" />
      </Grid>

      <Grid item xs={12} marginLeft={2} marginRight={2}>
        <Banner/>
        {/* <img src="./banner01.jpg" alt="Real Estate" style={{ width: "100%" }} /> */}
      </Grid>

      <Grid item xs={12} md={12} margin={2}>
        <Grid container spacing={2} sx={{ padding: "15px 0" }}>
          <Grid item md={1.5} xs={4}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                backgroundColor: "#1976d2",
                color: "white",
                padding: "20px",
                fontSize: "35px",
                width: "125px",
                height: "125px",
                // "&:hover": {
                //   backgroundColor: "#038c5a",
                // },
              }}
            >
              誠信
            </Button>
          </Grid>
          <Grid item md={2.2} xs={7}>
            <Typography
              variant="h5"
              sx={{
                // display: "inline-block", // Ensures inline behavior
                textAlign: "left", // Horizontally centers the text
                lineHeight: "1.5", // Vertically centers the text
                padding: "25px 0",
                // minHeight: '85px'
              }}
            >
              成立逾五十年，深耕澳門房地產市場。
            </Typography>
          </Grid>
          <Grid item md={1.5} xs={4}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                backgroundColor: "#1976d2",
                color: "white",
                padding: "20px",
                fontSize: "35px",
                width: "125px",
                height: "125px",
                // "&:hover": {
                //   backgroundColor: "#038c5a",
                // },
              }}
            >
              專業
            </Button>
          </Grid>
          <Grid item md={2.2} xs={7}>
            <Typography
              variant="h5"
              sx={{
                // display: "inline-block", // Ensures inline behavior
                textAlign: "left", // Horizontally centers the text
                lineHeight: "1.5", // Vertically centers the text
                padding: "5px 0",
                // minHeight: '85px'
              }}
            >
              開展澳門房地產評估業務逾三十年，擁有專業估價團隊。
            </Typography>
          </Grid>
          <Grid item md={1.5} xs={4}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                backgroundColor: "#1976d2",
                color: "white",
                padding: "20px",
                fontSize: "35px",
                width: "125px",
                height: "125px",
                // "&:hover": {
                //   backgroundColor: "#038c5a",
                // },
              }}
            >
              資深
            </Button>
          </Grid>
          <Grid item md={2.2} xs={7}>
            <Typography
              variant="h5"
              sx={{
                // display: "inline-block", // Ensures inline behavior
                textAlign: "left", // Horizontally centers the text
                lineHeight: "1.5", // Vertically centers the text
                padding: "25px 0",
                // minHeight: '85px'
              }}
            >
              澳門房地產評估業協會會員單位。
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Index;
