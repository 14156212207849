/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Tooltip,
  Button,
  IconButton,
  TextField,
  Box,
  FormControl,
  useMediaQuery,
} from "@mui/material";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// import fileDownload from "js-file-download";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import PasswordIcon from "@mui/icons-material/Password";
import DownloadIcon from "@mui/icons-material/Download";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
// import RestoreIcon from "@mui/icons-material/Restore";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AssessmentIcon from "@mui/icons-material/Assessment";
import * as XLSX from "xlsx";
// import { CircularProgress, Backdrop } from "@mui/material";

import Fab from "@mui/material/Fab";
import Autorenew from "@mui/icons-material/Autorenew.js";

// @ts-ignore
import {
  baseURL,
  timeformat,
  dateformat,
  yearformat,
  monthformat,
  construct_building_code,
  currencyValueFormatter,
  saveCurrentTimeToLocalStorage,
  isValidDate,
  // @ts-ignore
} from "./settings.tsx";

// @ts-ignore
import { removeSQLInjectionKeywords, alert_error } from "./settings.tsx";

// @ts-ignore
import Edit from "./Edit.tsx";
// @ts-ignore
import Add from "./Add.tsx";
// @ts-ignore
import Report from "./Report.tsx";
// @ts-ignore
import Printout from "./Printout.tsx";
// @ts-ignore
import Picture from "./Picture.tsx";
// @ts-ignore
import ColSearch from "./ColSearch.tsx";
// @ts-ignore
import ChangePassword from "./ChangePassword.tsx";
// @ts-ignore
import Footer from "./Footer.tsx";

import moment from "moment";

type NewRow = {
  [key: string]: any;
  row_id: string;
  code_branch: string;
  code_type: string;
  code_year: string;
  code_month: string;
  code_serial: string;

  application_date: string;
  application_user: string;
  job_assign: string;
  conversion_date: string;
  conversion_user: string;
  consigner_company: string;
  consigner_branch: string;
  client_name: string;
  building_name: string;
  building_phase: string;
  building_estate: string;
  building_tower: string;
  building_floor: string;
  building_block: string;
  building_address: string;
  consigner_type: string;
  consigner_tel: string;
  consigner_fax: string;
  consign_date: string;
  consigner_contact: string;
  fee_code: string;
  evaluation_purpose: string;
  // evaluation_remark: string;
  evaluation_date: string;
  re_evaluation: string;
  appendix01: string;
  building_code: string;
  // building_location: string;
  registration_area: string;
  // saleable_area: string;
  // composition: string;
  // special_remark: string;
  license_issue_year: string;
  // building_age: string;
  // registration_usage_type: string;
  // client_evaluation_usage: string;
  apartment_description: string;
  // identification_code: string;
  seed_code: string;
  // terrace_area: string;
  evaluation_item01: string;
  evaluation_item02: string;
  evaluation_item03: string;
  evaluation_item04: string;
  picture: string;
  floor: string;
  specific_value1: string;
  specific_value2: string;
  specific_value3: string;
  specific_value4: string;
  final_fee: string;
  // rebuild_rate: string;
  // promote_rate: string;
  modification_count: string;
  status: string;
};

const newRow: NewRow = {
  row_id: "",
  code_branch: "",
  code_type: "",
  code_year: "",
  code_month: "",
  code_serial: "",
  application_date: "",
  application_user: "",
  job_assign: "",
  conversion_date: "",
  conversion_user: "",
  consigner_company: "",
  consigner_branch: "",
  fee_code: "",
  client_name: "",
  building_name: "",
  building_phase: "",
  building_estate: "",
  building_tower: "",
  building_floor: "",
  building_block: "",
  building_address: "",
  consigner_type: "",
  consigner_tel: "",
  consigner_fax: "",
  consign_date: "",
  consigner_contact: "",
  evaluation_purpose: "",
  // evaluation_remark: "",
  evaluation_date: "",
  re_evaluation: "",
  appendix01: "",
  building_code: "",
  // building_location: "",
  registration_area: "",
  // saleable_area: "",
  // composition: "",
  // special_remark: "",
  license_issue_year: "",
  // building_age: "",
  // registration_usage_type: "",
  // client_evaluation_usage: "",
  apartment_description: "",
  // identification_code: "",
  seed_code: "",
  // terrace_area: "",
  evaluation_item01: "",
  evaluation_item02: "",
  evaluation_item03: "",
  evaluation_item04: "",
  picture: "",
  floor: "",
  specific_value1: "",
  specific_value2: "",
  specific_value3: "",
  specific_value4: "",
  final_fee: "",
  // rebuild_rate: "",
  // promote_rate: "",
  modification_count: "",
  status: "",
};

type NewRowSeed = {
  row_id: string;
  application_date: string;
  application_user: string;
  code_branch: string;
  code_type: string;
  code_year: string;
  code_month: string;
  code_serial: string;
  consigner_company: string;
  consigner_branch: string;
  fee_code: string;
  consigner_fax: string;
  consigner_tel: string;
  consigner_type: string;
  consign_date: string;
  status: string;
  seed_code: string; // for copy row
};

const newRowSeed: NewRowSeed = {
  row_id: "",
  application_date: "",
  application_user: "",
  code_branch: "",
  code_type: "",
  code_year: "",
  code_month: "",
  code_serial: "",
  consigner_company: "",
  consigner_branch: "",
  fee_code: "",
  consigner_fax: "",
  consigner_tel: "",
  consigner_type: "",
  consign_date: "",
  status: "",
  seed_code: "", // to search for reply to copy
};

function Search() {
  const [col_search, setColSearch] = useState(false);
  const [col_width, setColWidth] = useState([
    80, 80, 100, 120, 200, 80, 80, 80, 60, 80, 120, 100, 100, 100, 100, 100, 200, 200, 100, 200, 200, 100
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalRows, setTotalRows] = useState(-1);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [report, setReport] = useState(false);
  const [printout, setPrintout] = useState(false);
  const [picture, setPicture] = useState(false);
  const [source_code, setSourceCode] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  // const [replyInfo, setReplyInfo] = useState(false);
  let newRows: Array<NewRow> = [];
  const [rows, setRows] = useState(newRows);
  const [selectedRow, setSelectedRow] = useState(newRow);
  let emptyIds: Array<number> = [];
  const [selectedIds, setSelectedIds] = useState(emptyIds);
  const [defaultRow, setDefaultRow] = useState(newRowSeed);
  const [searchOption, setSearchOption] = useState("0");
  const [username, setUserName] = useState("");
  const [code_branch, setCodeBranch] = useState("");
  const [loginTimeLeft, setLoginTimeLeft] = useState(30);
  // const [loginTimeColor, setLoginTimeColor] = useState("primary");
  const [loginTimeColor, setLoginTimeColor] = useState("#1976d2");

  const handleUpdateColWidth = (col:number, width: number) => {
    const updatedColWidth = [...col_width]; // Create a copy of col_width
    updatedColWidth[col-1] = width; // Update the value at index 1
    setColWidth(updatedColWidth); // Update the state with the modified array
  };

  function hanleRefreshLogin() {
    if (loginTimeLeft > 1) {
      keep_login();
    } else {
      navigate("/main");
    }
  } 

  useEffect(() => {
    if (loginTimeLeft>5) {
      setLoginTimeColor("#1976d2");
    } else if (loginTimeLeft>1) {
      setLoginTimeColor("#d32f2f");
    } else {
      setLoginTimeColor("#222222");
    }
  },[loginTimeLeft]);  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const login_time_passed = getCurrentTimeFromLocalStorage();
      const login_time_left = Math.max(30 - login_time_passed, 0);
      setLoginTimeLeft(login_time_left);
    }, 5 * 1000); // Call the function every minute

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, []);

  function getCurrentTimeFromLocalStorage(): number {
    const savedTime: string | null = localStorage.getItem("token_time");
    if (savedTime) {
      const currentTime: string = timeformat(new Date());
      const savedMoment = moment(savedTime, "YYYY-MM-DD HH:mm");
      const currentMoment = moment(currentTime, "YYYY-MM-DD HH:mm");

      const timeDifferenceMinutes: number = currentMoment.diff(
        savedMoment,
        "minutes"
      );
      return timeDifferenceMinutes;
    } else {
      return 0;
    }
  }

  function keep_login(): Promise<boolean> {
    return axios({
      url: baseURL + "/login",
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(
            new Error("登入失敗代號：(LOGIN FAIL CODE)" + response.status)
          );
        }
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();
        setLoginTimeLeft(30); // succeed
        return true;
      })
      .catch((error) => {
        alert("保持登入不成功，可能已經超時，請重新登入。");
        return false; // This return is ignored within a catch block of a Promise
      });
  }

  async function searchReply(code_reply: string) {

    if (code_reply.length < 18 ) {
      alert("估價/回覆編號無效："+code_reply);
      return;
    }

    const formValues = {
      kw1: code_reply,
      kw2: "",
      date_search_1: "",
      date_search_2: "",
    };

    search_db("5", 0, pageSize, formValues);
  }

  async function downloadPDF(source_code: string) {
    setSourceCode(source_code);
    setPrintout(true);
    // console.log(printout);
  }

  function error_action(error: any) {
    alert_error(error);
    if (error.response && error.response.status === 401) {
      navigate("/main");
    } else {
    }
  }

  function download() {
    setReport(true);
  }

  function password() {
    setChangePassword(true);
  }

  useEffect(() => {
    const username_temp = localStorage.getItem("username") || "";
    const branch_code_temp = localStorage.getItem("branch_code") || "";
    const allow_change_password_temp = localStorage.getItem("allow_change_password") || "";
    setUserName(username_temp);
    setCodeBranch(branch_code_temp);
    if (allow_change_password_temp === "1") {
      setChangePassword(true);
    } else {
      // alert(allow_change_password_temp);
      search_db("0", page, pageSize, formValues);
    }
  }, []); //  2023.11.20

  useEffect(() => {
    // alert(page)
    if (totalRows >= 0) {
      search_db(searchOption, page, pageSize, formValues);
    }
  }, [page, pageSize]);
  // should NOT include searchOption for it will trigger twice by button

  function search_db(
    search_option: string,
    page: number,
    page_size: number,
    formValues: any
  ) {
    const ds1 = formValues.date_search_1;
    const ds2 = formValues.date_search_2;

    if (ds1 !== "" && !isValidDate(ds1)) {
      alert("開始日期無效。");
      return;
    }

    if (ds2 !== "" && !isValidDate(ds2)) {
      alert("結束日期無效。");
      return;
    }

    if (ds1 !== "" && ds2 !== "" && ds2 < ds1) {
      alert("日期範圍不合。")
      return;
    }

    setLoading(true);

    const search_option_web = search_option==="5"?"4":search_option;

    axios({
      method: "GET",
      url: baseURL + "/search/" + search_option_web + "/" + page + "/" + page_size,
      params: formValues,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        setLoading(false);

        if (search_option === "5" && response.data.list.length === 0) {
          alert("找不到相應回覆。");
          return;
        }
        setSelectedIds([]);
        // console.log(response.data.list);
        setRows(response.data.list);
        if (page === 0) {
          setTotalRows(response.data.total_rows);
        }
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();

        setSearchOption(search_option_web);
        // alert(search_option_web);
        setPage(page);
        setPageSize(pageSize);
        // setLoading(false);  
      })
      .catch((error) => {
        setLoading(false);
        error_action(error);
      });
           
  }

  const listToClipboard = () => {
    // Header row
    // const dataString =
    //   columns
    //     .slice(2)
    //     .map((column) => column.headerName)
    //     .join("\t") + "\n";

    const dataString =
        columns.slice(2,10).map((column) => column.headerName).join("\t")+
        "\t地址\t"+
        columns.slice(10,23).map((column) => column.headerName).join("\t") + "\n";
          
    setLoading(true);

    axios({
      method: "GET",
      url: baseURL + "/list/" + searchOption,
      params: formValues,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      // timeout: 60000,
    })
      .then((response) => {
        const dataRows = response.data.list;
        const dataToCopy = dataString + dataRows;
        copyToClipboard(dataToCopy);
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();

        // Export to Excel
        const rows = dataToCopy.split("\n");
        const data: any[][] = rows.map((row: any) => row.split("\t"));

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

        const range: XLSX.Range = XLSX.utils.decode_range(
          worksheet["!ref"] || ""
        );
        worksheet["!autofilter"] = { ref: XLSX.utils.encode_range(range) };
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

        worksheet["!cols"] = [{ width: 12 }];
        worksheet["!cols"][1] = { width: 10 };
        worksheet["!cols"][2] = { width: 20 };
        worksheet["!cols"][4] = { width: 15 };
        worksheet["!cols"][8] = { width: 25 };
        worksheet["!cols"][17] = { width: 25 };
        worksheet["!cols"][18] = { width: 25 };
        worksheet["!cols"][19] = { width: 25 };
        worksheet["!cols"][20] = { width: 25 };
        worksheet["!cols"][21] = { width: 20 };

        XLSX.writeFile(workbook, "output.xlsx");

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        error_action(error);
      });
  };

  function copyToClipboard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        alert("清單已經匯出至Excel和剪貼板，可以粘貼到Excel和Word等其他軟件。");
      })
      .catch(function (error) {
        alert("無法覆制至剪貼板: " + error);
      });
  }

  function copyRow(seed_code: string) {
    const d = new Date();

    setDefaultRow({
      row_id: "",
      application_date: timeformat(d),
      application_user: "", //login_user.username,
      code_branch: "", //branch.branch_code,
      code_type: "",
      code_year: yearformat(d),
      code_month: monthformat(d),
      code_serial: "",
      consigner_company: "", // company.name,
      consigner_branch: "", // branch.name,
      fee_code: "",
      consigner_fax: "", // branch.fax,
      consigner_tel: "", // branch.tel,
      consigner_type: "", // branch.consigner_type,
      consign_date: dateformat(d),
      status: "",
      seed_code: seed_code,
    });
    console.log(5);

    setAdd(true);
    //
  }

  function updateRows(newrow: NewRow) {
    let newRows = [...rows]; // copy rows to new array
    const foundIndex = newRows.findIndex((row) => row.row_id === newrow.row_id);
    newRows[foundIndex] = newrow; // replace the object, it works
    setRows(newRows);
  }

  function handleMove(increment: number) {
    if (selectedIds.length === 0) {
      alert("請先選取一筆記錄。");
      return;
    }
    // let foundIndex = rows.findIndex(row => row.row_id == selectedIds[0].toString());
    let foundIndex = rows.findIndex(
      (row) => parseInt(row.row_id) === selectedIds[0]
    );
    if (foundIndex < 0) {
      alert("找不到當前記錄！");
      return;
    }

    foundIndex = foundIndex + increment;
    if (foundIndex <= -1 || foundIndex >= rows.length) {
      alert("已經是本頁的第一筆或最後一筆。");
      return;
    }

    let row_id = rows[foundIndex].row_id;
    setSelectedIds([Number(row_id)]);
    console.log("row_id:");
    console.log(row_id);
    selectRow(row_id);
  }

  function appendRows(newrow: NewRow) {
    // console.log(newrow);
    let newRows = [...rows]; // copy rows to new array
    // newRows.push(newrow);
    newRows.splice(0, 0, newrow);
    setRows(newRows);

    newrow.application_date = timeformat(newrow.application_date);
    newrow.conversion_date = timeformat(newrow.conversion_date);
    setSelectedRow(newrow);
    // here should select this rows
    setSelectedIds([Number(newrow.row_id)]);
    setAdd(false);
    setEdit(true);
    //  alert('appended');
  }

  function selectRow(row_id: string) {
    //  alert(row_id);
    let selectedRows = rows.filter(
      (row) => parseInt(row.row_id) === parseInt(row_id)
    );
    if (selectedRows.length === 0) {
      alert("找不到選取的記錄(" + row_id + ")。");
      return;
    }
    let tempRow = selectedRows[0];
    tempRow.application_date = timeformat(selectedRows[0].application_date);
    tempRow.conversion_date = timeformat(selectedRows[0].conversion_date);
    setSelectedRow(tempRow);
  }

  function editRow() {
    setEdit(true);
  }

  function deleteRow(row_id: any) {
    let text = "刪除一筆申請？(ID=" + row_id + ")";
    if (window.confirm(text) === false) {
      return;
    }

    let newRows = [...rows]; // copy rows to new array
    const foundIndex = newRows.findIndex(
      (row) => parseInt(row.row_id) === row_id
    );
    console.log(foundIndex);
    if (foundIndex < 0) {
      alert("找不到記錄！");
      return;
    }
    // alert(foundIndex);

    axios({
      method: "GET",
      url: baseURL + "/delete/" + row_id,
      // params: formValues,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        console.log("response:");
        console.log(response);

        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();
        // let conversion_date = JSON.parse(response.data.list).conversion_date;
        let conversion_date = response.data.list.conversion_date;
        if (conversion_date !== null) {
          alert(timeformat(conversion_date) + " 已經轉換，不能刪除。"); // should NOT
          return;
        }
        newRows.splice(foundIndex, 1);
        setRows(newRows);
        setSelectedIds([]);
      })
      .catch((error) => {
        error_action(error);
      });
  }

  function logout() {
    if (window.confirm("停止操作後，登入保留半小時。你確定登出嗎？") === true) {
      localStorage.removeItem("access_token");
      // localStorage.removeItem("username"); // 2024-02-23
      navigate("/main");
    }
  }

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const defaultSearch = {
    date_search_1: dateformat(firstDayOfMonth),
    date_search_2: dateformat(lastDayOfMonth),
    kw1: "",
    kw2: "",
  };

  const [formValues, setFormValues] = useState(defaultSearch);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let new_value;
    new_value = removeSQLInjectionKeywords(value);
    setFormValues({
      ...formValues,
      [name]: new_value,
    });
  };

  function setValue(name: string, new_value: any) {
    setFormValues({
      ...formValues,
      [name]: dateformat(new_value),
    });
  }

  const columns: GridColDef[] = [
    // { field: "row_id", headerName: "ID", width: 60 },
    {
      field: "status",
      headerName: "狀態",
      width: col_width[0],
      headerAlign: "center",
      align: "center",
      // valueFormatter: ({ value }) => formatStatusToLabel(value),
      renderCell: (params) => {
        const rowData = params.row;
        const status = String(rowData.status);
        let color = "";
        let label = "";
        if (status === "1") {
          color = "red";
          label = "草稿";
        } else if (status === "2") {
          color = "orange";
          label = "待轉";
        } else if (status === "3") {
          color = "blue";
          label = "待覆";
        } else if (status === "4") {
          color = "green";
          label = "已覆";
        } else if (status === "5") {
          color = "green";
          label = "已覆";
        } else {
          color = "black";
          label = "不明";
        }
        return <div style={{ color }}>{label}</div>;
      },
    },

    {
      field: "actions",
      headerName: "操作",
      width: col_width[1],
      headerAlign: "center",
      renderCell: (params) => {
        const rowData = params.row;

        let status = 0; // unknown
        rowData.status = ""; // formatStatusToLabel(status);

        if (rowData.job_assign === null || rowData.job_assign === "") {
          status = 1; // draft, not submitted, job_assign has not assigned
          rowData.status = "草稿"; // formatStatusToLabel(status);
        } else if (rowData.job_assign !== null && rowData.job_assign !== "") {
          status = 2; // submitted, job_assign has assigned
          rowData.status = "待轉"; // formatStatusToLabel(status);
          if (
            rowData.conversion_date !== null &&
            rowData.conversion_date !== ""
          ) {
            status = 3; // converted
            rowData.status = "待覆"; // formatStatusToLabel(status);
          }
          if (
            (rowData.specific_value1 !== null &&
              Number(rowData.specific_value1) > 0) ||
            (rowData.specific_value2 !== null &&
              Number(rowData.specific_value2) > 0) ||
            (rowData.specific_value3 !== null &&
              Number(rowData.specific_value3) > 0) ||
            (rowData.specific_value4 !== null &&
              Number(rowData.specific_value4) > 0)
          ) {
            status = 4;
            rowData.status = "已覆"; // formatStatusToLabel(status);
          }
        }
        if (searchOption === "4") {
          status = 5;
          rowData.status = "已覆"; // formatStatusToLabel(status);
        }

        rowData.status = status; // formatStatusToLabel(status);

        let shouldDisableInfoIcon = false;
        let shouldDisableEditIcon = false;
        let shouldDisableDeleteIcon = false;
        let shouldDisableAssessmentIcon = false;
        let shouldDisablePDFIcon = false;

        if (status === 0) {
          alert(rowData.row_id + " 狀態不明。");
        } else if (status === 1) {
          // draft
          shouldDisableInfoIcon = true;
          shouldDisableEditIcon = false;
          shouldDisableDeleteIcon = false;
          shouldDisableAssessmentIcon = true;
          shouldDisablePDFIcon = true;
        } else if (status === 2) {
          // submitted
          shouldDisableEditIcon = true;
          shouldDisableDeleteIcon = true;
          shouldDisableAssessmentIcon = true;
          shouldDisablePDFIcon = true;
        } else if (status === 3) {
          shouldDisableEditIcon = true;
          shouldDisableDeleteIcon = true;
          shouldDisableAssessmentIcon = true;
          shouldDisablePDFIcon = true;
        } else if (status === 4) {
          shouldDisableEditIcon = true;
          shouldDisableDeleteIcon = true;
          shouldDisableAssessmentIcon = false;
          shouldDisablePDFIcon = true;
        } else if (status === 5) {
          shouldDisableEditIcon = true;
          shouldDisableDeleteIcon = true;
          shouldDisableAssessmentIcon = true;
          shouldDisablePDFIcon = false;
        }

        // console.log("username: " + username);

        if (rowData.application_user !== username 
          && code_branch !== "0000") {
          // not the owner
          shouldDisableInfoIcon = false; // can view
          shouldDisableEditIcon = true; // cannot edit
          shouldDisableDeleteIcon = true; // cannot delete
        }

        return (
          <div>
            {!shouldDisableInfoIcon && (
              <Tooltip title="查看估價申請">
                <IconButton
                  sx={{ width: 20 }}
                  disabled={shouldDisableInfoIcon}
                  // onClick={() => replyRow()}
                  onClick={() => editRow()}
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      color: shouldDisableInfoIcon ? "#AAAAAA" : "#3085C3",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!shouldDisableEditIcon && (
              <Tooltip title="編輯估價申請">
                <IconButton
                  color="primary"
                  sx={{ width: 20 }}
                  onClick={() => editRow()}
                  disabled={shouldDisableEditIcon}
                >
                  <EditIcon
                    fontSize="small"
                    sx={{
                      color: shouldDisableEditIcon ? "#AAAAAA" : "#367E18",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!shouldDisableDeleteIcon && (
              <Tooltip title="刪除估價估價">
                <IconButton
                  sx={{ width: 20 }}
                  onClick={() => deleteRow(rowData.row_id)}
                  disabled={shouldDisableDeleteIcon}
                >
                  <DeleteIcon
                    fontSize="small"
                    sx={{
                      color: shouldDisableDeleteIcon ? "#AAAAAA" : "#D71313",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {!shouldDisablePDFIcon && (
              <Tooltip title="下載估價結果PDF">
                <IconButton
                  sx={{ width: 20 }}
                  onClick={() => downloadPDF(construct_building_code(rowData))}
                  disabled={shouldDisablePDFIcon}
                >
                  <PictureAsPdfIcon
                    fontSize="small"
                    sx={{ color: shouldDisablePDFIcon ? "#AAAAAA" : "#A47963" }}
                  />
                </IconButton>
              </Tooltip>
            )}

            {!shouldDisableAssessmentIcon && (
              <Tooltip title="查找回覆結果記錄">
                <IconButton
                  sx={{ width: 20 }}
                  // onClick={() => searchReply(construct_building_code(rowData))}
                  onClick={() => searchReply(rowData.seed_code)} // 2024.04.24
                  disabled={shouldDisableAssessmentIcon}
                >
                  <AssessmentIcon
                    fontSize="small"
                    sx={{
                      color: shouldDisableAssessmentIcon
                        ? "#AAAAAA"
                        : "#793FDF",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },

    {
      field: "consign_date",
      headerName: "委託日期",
      width: col_width[2],
      valueFormatter: ({ value }) => dateformat(value),
    },
    { field: "building_code", headerName: "標示編號", width: col_width[3] },
    { field: "building_name", headerName: "物業名稱", width: col_width[4] },
    { field: "building_phase", headerName: "期/區", width: col_width[5] },
    { field: "building_estate", headerName: "苑", width: col_width[6] },
    { field: "building_tower", headerName: "棟/座", width: col_width[7] },
    { field: "building_floor", headerName: "樓", width: col_width[8] },
    { field: "building_block", headerName: "座", width: col_width[9] },
    // { field: "building_address", headerName: "地址", width: 200 },

    {
      field: "registration_area",
      headerName: "面積（平方米）",
      width: col_width[10],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },

    {
      field: "specific_value1",
      headerName: "估值",
      width: col_width[11],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },
    {
      field: "specific_value2",
      headerName: "租值",
      width: col_width[12],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },
    {
      field: "specific_value3",
      headerName: "重置價",
      width: col_width[13],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },
    {
      field: "specific_value4",
      headerName: "迫銷價",
      width: col_width[14],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },
    {
      field: "final_fee",
      headerName: "估價費MOP",
      width: col_width[15],
      valueFormatter: ({ value }) => currencyValueFormatter(value),
      align: "right",
    },

    { field: "conversion_user", headerName: "經辦人員", width: col_width[16] },
    {
      field: "building_code_str",
      headerName: searchOption==="0"?"申請編號":"回覆編號",
      width: col_width[17],
      valueGetter: (params) => {
        return construct_building_code(params.row);
      },
    },
    // {
    //   field: "seed_code",
    //   headerName: "來源編號",
    //   width: 200,
    //   // valueGetter: (params) => {
    //   //   return construct_building_code(params.row);
    //   // },
    // },
    { field: "consigner_company", headerName: "委託公司", width: col_width[18] },
    { field: "consigner_branch", headerName: "委託部門/分行", width: col_width[19] },
    { field: "client_name", headerName: "客戶名稱", width: col_width[20] },
    { field: "application_user", headerName: "申請人員", width: col_width[21] },

    // { field: 'building_address', headerName: '地址', width: 300 },
  ];

  return (
    <div style={{ height: 800, width: "100%" }}>
      <Box display="flex" justifyContent="center" style={{ margin: 10 }}>
        <Grid container columnSpacing={1} rowSpacing={1} alignItems="center">
          <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
            <Typography
              color="primary"
              component={Link}
              to="/"
              variant="body1"
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                fontSize: isSmallScreen ? "36px" : "36px",
                wordBreak: "keep-all",
                textDecoration: "none",
              }}
            >
              德記置業有限公司
            </Typography>
            <div style={{}}>
              <Fab
                color="primary"
                aria-label="刷新"
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  width: "160px",
                  height: "50px",
                  borderRadius: "8px", // Add this line to set the border radius
                  display: "flex", // Add this line to enable centering of content
                  flexDirection: "row", // Add this line to stack the icon and text vertically
                  justifyContent: "center", // Add this line to center the content vertically
                  alignItems: "center", // Add this line to center the content horizontally
                  zIndex: 9999,
                  backgroundColor: loginTimeColor, // Set the background color dynamically
                }}
                onClick={hanleRefreshLogin}
              >
                <Autorenew />
                登入剩餘：{loginTimeLeft>0?String(loginTimeLeft):"--"}分鐘
              </Fab>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ textAlign: "left" }}
            // alignItems="left"
          >
            <Box display="flex" alignItems="center" paddingLeft="10px">
              <Tooltip title="申請清單匯出至剪貼板">
                <IconButton
                  color="primary"
                  sx={{ width: 20, marginRight: "3px", cursor: "pointer" }}
                  onClick={listToClipboard}
                >
                  <SummarizeIcon fontSize="medium" color="warning" />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body1"
                sx={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={listToClipboard}
              >
                匯出
              </Typography>

              <Tooltip title="新增估價申請">
                <IconButton
                  color="primary"
                  sx={{ width: 20, marginRight: "3px" }}
                  onClick={()=>copyRow("")}
                >
                  <AddIcon fontSize="medium" color="success" />
                </IconButton>
              </Tooltip>
              <Typography
                color="primary"
                variant="body1"
                sx={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={()=>copyRow("")}
              >
                新增
              </Typography>

              <Tooltip title="修改登入密碼">
                <IconButton
                  color="secondary"
                  sx={{ width: 20, marginRight: "3px" }}
                  onClick={password}
                >
                  <PasswordIcon fontSize="medium" color="secondary" />
                </IconButton>
              </Tooltip>
              <Typography
                color="primary"
                variant="body1"
                sx={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={password}
              >
                密碼
              </Typography>

              <Tooltip title="下載月結單報表">
                <IconButton
                  color="success"
                  sx={{ width: 20, marginRight: "3px" }}
                  onClick={download}
                >
                  <DownloadIcon fontSize="medium" sx={{ color: "#A47963" }} />
                </IconButton>
              </Tooltip>
              <Typography
                color="primary"
                variant="body1"
                sx={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={download}
              >
                報表
              </Typography>

              <Tooltip title="登出系統">
                <IconButton
                  color="error"
                  sx={{ width: 20, marginRight: "3px" }}
                  onClick={logout}
                >
                  <LogoutIcon fontSize="medium" color="error" />
                </IconButton>
              </Tooltip>
              <Typography
                color="primary"
                variant="body1"
                sx={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={logout}
              >
                登出
              </Typography>
            </Box>

            {/* </div> */}
          </Grid>

          <Grid item xs={6} md={1.5} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <FormControl fullWidth>
                {/* sx={{ color: "success" }} */}
                <DatePicker
                  label="開始日期"
                  value={formValues.date_search_1}
                  onChange={(newValue) => {
                    setValue("date_search_1", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      focused
                      fullWidth
                      required
                      // sx={{ "& input": { color: "success" } }}
                    />
                  )}
                  inputFormat="yyyy-MM-DD"
                  InputProps={{ sx: { height: 40 } }}
                  // required
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={1.5} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <FormControl fullWidth>
                {/* sx={{ color: "success" }} */}
                <DatePicker
                  label="結束日期"
                  value={formValues.date_search_2}
                  onChange={(newValue) => {
                    setValue("date_search_2", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      focused
                      fullWidth
                      required
                      // sx={{ "& input": { color: "success" } }}
                    />
                  )}
                  inputFormat="yyyy-MM-DD"
                  InputProps={{ sx: { height: 40 } }}
                  // required
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} md={1.5} alignItems="center">
            <TextField
              focused
              fullWidth
              inputProps={{ maxLength: 40 }}
              InputProps={{ sx: { height: 40 } }}
              value={formValues.kw1}
              onChange={handleInputChange}
              label="含字串"
              id="kw1"
              name="kw1"
            />
          </Grid>
          <Grid item xs={6} md={1.5} alignItems="center">
            <TextField
              focused
              fullWidth
              inputProps={{ maxLength: 40 }}
              InputProps={{ sx: { height: 40 } }}
              value={formValues.kw2}
              onChange={handleInputChange}
              label="及字串"
              id="kw2"
              name="kw2"
            />
          </Grid>

          <Grid item xs={6} md={3} alignItems="center">
            <Button
              sx={{
                backgroundColor: "#388e3c",
                color: "#FFFFFF",
                border:
                  searchOption === "0"
                    ? "3px solid #FF0000"
                    : "3px solid #388e3c",
              }}
              fullWidth
              onClick={() => {
                search_db("0", 0, pageSize, formValues);
              }}
              variant="contained"
            >
              搜尋申請
            </Button>
          </Grid>

          <Grid item xs={6} md={3} alignItems="center">
            <Button
              sx={{
                backgroundColor: "#455a64",
                color: "#FFFFFF",
                border:
                  searchOption === "4"
                    ? "3px solid #FF0000"
                    : "3px solid #455a64",
              }}
              fullWidth
              onClick={() => {
                search_db("4", 0, pageSize, formValues);
              }}
              variant="contained"
            >
              搜尋回覆
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DataGrid
        // {...gridOptions}
        paginationMode="server"
        pagination
        page={page}
        rowCount={totalRows}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // onPageChange={handlePageChange}

        getRowId={(row) => row.row_id}
        rows={rows}
        columns={columns}
        loading={loading}
        headerHeight={40}
        sx={{
          height: 720,
        }}
        rowHeight={30}
        initialState={{
          pagination: {
            pageSize: 20,
          },
        }}
        rowsPerPageOptions={[20, 50, 100]}
        onSelectionModelChange={(ids) => {
          if (ids.length === 0) {
            return;
          }
          let temp_row_id = Number(ids[0]);
          setSelectedIds([temp_row_id]);
          selectRow(temp_row_id.toString());
        }}
        onRowDoubleClick={(params) => {
          let temp_row_id = Number(params.row.row_id);
          setSelectedIds([temp_row_id]);
          selectRow(temp_row_id.toString());
          editRow();
        }}
        selectionModel={selectedIds}
      />
      <Edit
        edit={edit}
        setEdit={setEdit}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        updateRows={updateRows}
        handleMove={handleMove}
        // removeSubmit={removeSubmit}
        downloadPDF={downloadPDF}
        // setAdd={setAdd}
        copyRow={copyRow}
        searchOption={searchOption}
        // username={username}
        setPicture={setPicture}
      />
      <Add
        add={add}
        setAdd={setAdd}
        setSelectedIds={setSelectedIds}
        defaultRow={defaultRow}
        appendRows={appendRows}
      />
      <Report report={report} setReport={setReport} />
      <Printout printout={printout} setPrintout={setPrintout} source_code={source_code}/>      
      <Picture picture={picture} setPicture={setPicture} row_picture={selectedRow.picture}/>      
      <ColSearch col_search={col_search} setColSearch={setColSearch} col_width={col_width} handleUpdateColWidth={handleUpdateColWidth}/>      
      <ChangePassword
        changePassword={changePassword}
        setChangePassword={setChangePassword}
      />

      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}
      >
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          操作說明：
        </Typography>
        <Tooltip title="查看估價申請">
          <IconButton sx={{ width: 20, marginRight: "5px" }}>
            <InfoIcon
              fontSize="small"
              sx={{
                color: "#3085C3",
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          查看申請
        </Typography>
        <Tooltip title="編輯估價申請">
          <IconButton
            color="primary"
            sx={{ width: 20, marginRight: "5px" }}
            onClick={() => editRow()}
          >
            <EditIcon
              fontSize="small"
              sx={{
                color: "#367E18",
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          編輯申請
        </Typography>
        <Tooltip title="刪除估價估價">
          <IconButton sx={{ width: 20, marginRight: "5px" }}>
            <DeleteIcon
              fontSize="small"
              sx={{
                color: "#D71313",
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          刪除申請
        </Typography>
        <Tooltip title="查找回覆結果記錄">
          <IconButton sx={{ width: 20, marginRight: "5px" }}>
            <AssessmentIcon fontSize="small" sx={{ color: "#793FDF" }} />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          查找回覆
        </Typography>

        <Tooltip title="下載估價結果PDF">
          <IconButton sx={{ width: 20, marginRight: "5px" }}>
            <PictureAsPdfIcon fontSize="small" sx={{ color: "#A47963" }} />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" sx={{ marginRight: "20px" }}>
          下載結果
        </Typography>
        <Button variant="contained" color="secondary" sx={{height: 25}} onClick={()=>{setColSearch(true)}}>
              欄目寬度
        </Button>
      </div>
      <Footer />
    </div>
  );
}

export default Search;
