/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";

import * as React from "react";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";

// @ts-ignore
import {
  baseURL,
  alert_error,
  DEVELOPMENT,
  saveCurrentTimeToLocalStorage,
  // @ts-ignore
} from "./settings.tsx";

// 2023.10.05 modified, see backup: ./react-simple-captcha.js
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "./react-simple-captcha.js";

function Login() {
  useEffect(() => {
    const local_username = localStorage.getItem("username") || "";
    console.log("get username from localstorage = " + local_username);
    if (local_username !== "") {
      setFormValues({
        ...formValues,
        username: local_username,
      });
    }
    login(); // check whether the user is already logged in
    loadCaptchaEnginge(4);
  }, []);

  const navigate = useNavigate();

  function error_action(error: any, alert: any = 1) {
    if (alert === 1) alert_error(error);
    if (error.response.status === 401) {
      navigate("/main");
    }
  }

  function login() {
    axios({
      url: baseURL + "/login",
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        localStorage.setItem("access_token", response.data.token.access_token);
        saveCurrentTimeToLocalStorage();
        if (
          window.confirm(
            "用戶（" +
              response.data.username +
              "）已經登入，在有效時間內。進入系統嗎？"
          ) === true
        ) {
          navigate("/search");
        }
      })
      .catch((error) => {
        error_action(error, 0);
      });
  }

  function handleSubmit(event: any) {
    event.preventDefault();

    if (formValues.username.trim() === "") {
      alert("用戶不能留空。");
      return;
    }

    if (formValues.password.trim() === "") {
      alert("密碼不能留空。");
      return;
    }

    if (DEVELOPMENT === false) {
      if (validateCaptcha(formValues.captcha) === true) {
        // alert('校驗碼正確 Captcha Matched');
      } else {
        alert("校驗碼不正確，請重新輸入。");
        return;
      }
    }

    var formData = new FormData();
    formData.append("username", formValues.username);
    formData.append("password", formValues.password);

    axios({ method: "POST", url: baseURL + "/token", data: formData })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        saveCurrentTimeToLocalStorage();
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("code_branch", response.data.code_branch);
        localStorage.setItem(
          "allow_change_password",
          response.data.allow_change_password
        );
        // console.log("response.data.code_branch = "+response.data.code_branch);
        navigate("/search");
      })
      .catch((error) => {
        error_action(error);
      });
  }

  const defaultValues = {
    // username: DEVELOPMENT ? "timothy" : "",
    username: "", // timothy "", //
    password: DEVELOPMENT ? "Teng1234" : "",
    captcha: "", // "跳過檢驗",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        "& .MuiTextField-root": { marginTop: 0, marginBottom: 1 },
      }}
      noValidate
      autoComplete="off"
      width="100%"
    >
      <FormControl fullWidth>
        <Grid
          container
          marginTop={2}
          justifyItems="center"
          alignItems="center"
          marginBottom={1}
          columnSpacing={1}
          rowSpacing={1}
        >
          <Grid item xs={7} md={7} border={0}>
            <Grid
              container
              spacing={1}
              rowSpacing={1}
              justifyItems="center"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                md={4}
                sx={{
                  p: 1,
                  // display: "flex",
                  // justifyContent: "left",
                  // alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  用&nbsp;&nbsp;&nbsp;戶
                </Typography>
              </Grid>

              <Grid item xs={8} md={8}>
                <TextField
                  fullWidth
                  focused
                  InputProps={{ sx: { height: 45 } }}
                  id="username"
                  name="username"
                  // label="用戶"
                  value={formValues.username}
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                alignItems="left"
                sx={{
                  p: 1,
                  // display: "flex",
                  // justifyContent: "left",
                  // alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    textAlign: "left",
                    // paddingLeft: "0px",
                    fontSize: "20px",
                  }}
                >
                  密&nbsp;&nbsp;&nbsp;碼
                </Typography>
              </Grid>

              <Grid item xs={8} md={8}>
                <TextField
                  focused
                  fullWidth
                  InputProps={{ sx: { height: 45 } }}
                  id="password"
                  name="password"
                  // label="密碼"
                  value={formValues.password}
                  type="password"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                alignItems="left"
                sx={{
                  p: 1,
                  // display: "flex",
                  // justifyContent: "left",
                  // alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    textAlign: "left",
                    // paddingLeft: "0px",
                    fontSize: "20px",
                  }}
                >
                  驗證碼
                </Typography>
              </Grid>

              <Grid item xs={8} md={8}>
                <TextField
                  focused
                  fullWidth
                  InputProps={{ sx: { height: 45 } }}
                  id="captcha"
                  name="captcha"
                  // label="驗證碼"
                  value={formValues.captcha}
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      style={{ fontSize: 16 }}
                    >
                      登入系統
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ fontSize: 16 }}
                      onClick={() =>
                        alert("如忘記密碼，請撥打我司聯絡電話87970604。")
                      }
                    >
                      忘記密碼
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} md={5} border={0}>
            <Grid item xs={12} md={12} sx={{ marginTop: "16px" }}>
              <Typography variant="h4" align="center">
                驗證碼
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: "4px" }}>
              <LoadCanvasTemplate />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  );
}

export default Login;

//  HTTPS=true SSL_CRT_FILE="./cert/client-cert.pem" SSL_KEY_FILE="./cert/client-key.pem" npm start

//  HTTPS=true SSL_CRT_FILE="./cert/server-cert.pem" SSL_KEY_FILE="./cert/server-key.pem" npm start

// ssl_keyfile ="./cert/server-key.pem",
// ssl_certfile="./cert/server-cert.pem",
